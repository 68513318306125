import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container"]
  readonly containerTarget: HTMLElement

  focusableElements: HTMLElement[]
  firstElement: HTMLElement
  lastElement: HTMLElement

  private readonly selectors =
    "a[href]:not([disabled]), " +
    "button:not([disabled]), " +
    "textarea:not([disabled]), " +
    "input:not([disabled]), " +
    "select:not([disabled])"

  /**
   * On connecting controller modal, scan all focusable elements
   */
  connect(): void {
    this.focusableElements = Array.from(
      this.containerTarget.querySelectorAll(this.selectors),
    )
    this.setFirstandLast()

    document.addEventListener("keydown", this.handleTab, true)
  }

  disconnect(): void {
    if (document) {
      document.removeEventListener("keydown", this.handleTab, true)
    }
  }

  /**
   * In case of turbo frame, we need to reload array and add new elements
   */
  reloadFocusElements() {
    this.focusableElements = this.focusableElements.concat(
      Array.from(this.containerTarget.querySelectorAll(this.selectors)),
    )

    this.setFirstandLast()
  }

  /**
   * Handling tab key pressed, prevent the event if we are on the first or last element to loop on each other
   * @param event
   */
  handleTab = (event: KeyboardEvent): void => {
    if (event.key === "Tab") {
      if (event.shiftKey) {
        /* shift + tab */ if (
          (document.activeElement as HTMLElement) === this.firstElement
        ) {
          this.lastElement.focus()
          event.preventDefault()
        }
      } /* tab */ else {
        if ((document.activeElement as HTMLElement) === this.lastElement) {
          this.firstElement.focus()
          event.preventDefault()
        }
      }
    }
  }

  setFirstandLast(): void {
    this.firstElement = this.focusableElements[0]
    this.lastElement = this.focusableElements[this.focusableElements.length - 1]
  }
}
