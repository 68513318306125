import { ActionEvent, Controller } from "@hotwired/stimulus"
import { useDebounce, useClickOutside } from "stimulus-use"

export type Suggestion = {
  label: string
  id: string
}

export default class extends Controller {
  static values = {
    url: String,
    minLength: {
      type: Number,
      default: 2,
    },
    debounceWait: {
      type: Number,
      default: 100,
    },
  }
  readonly urlValue: string
  readonly minLengthValue: number
  readonly debounceWaitValue: number

  static targets = ["dropdown", "frame", "input"]
  readonly dropdownTarget: HTMLDivElement
  readonly frameTarget: HTMLElement
  readonly inputTarget: HTMLInputElement
  readonly hasInputTarget: boolean

  static classes = ["hiding"]
  readonly hidingClasses: Array<string>

  static debounces = [
    {
      name: "suggestIfNeeded",
    },
  ]

  connect(): void {
    useDebounce(this, {
      wait: this.debounceWaitValue,
    })
    useClickOutside(this, {
      events: [
        "click",
        "touchend",
        // Override default configuration to close when user focus somewhere else
        "focusin",
      ],
    })
  }

  clickOutside() {
    if (this.hasInputTarget && this.inputTarget.value.length > 0) {
      this.dispatch("click:outside:hasValue")
    }
  }

  suggestIfNeeded(event: InputEvent): void {
    const target = <HTMLInputElement>event.target
    if (target.value && target.value.length > this.minLengthValue) {
      // TODO: Add limit to remove search suggestions if there are already suggestions in the history ?
      const searchUrl = new URL(this.urlValue, location.origin)
      searchUrl.searchParams.append("term", target.value)
      this.frameTarget.setAttribute("src", searchUrl.toString())

      this.openDropdown()
    }
  }

  selectSuggestion(event: MouseEvent & ActionEvent): void {
    const suggestion: Suggestion = {
      id: event.params.id,
      label: event.params.label,
    }

    this.dispatch("selected", { detail: suggestion })

    this.closeDropdown()
  }

  openDropdown(): void {
    this.dropdownTarget.classList.remove(...this.hidingClasses)
  }

  closeDropdown(): void {
    this.dropdownTarget.classList.add(...this.hidingClasses)
  }
}
