import { ActionEvent, Controller } from "@hotwired/stimulus"
import { AnalyticsEvent } from "@models/analytics-event"

export default class extends Controller<HTMLFormElement> {
  getValues(event: ActionEvent) {
    if (
      event.params.property &&
      this.element.elements.namedItem(event.params.property)
    ) {
      const input = this.element.elements.namedItem(event.params.property)
      let value = undefined
      if (input instanceof RadioNodeList || input instanceof HTMLInputElement) {
        value = input.value
      }

      if (value) {
        this.dispatch("form-value", {
          detail: this._getAnalytics(value, event),
        })
      }
    }
  }

  private _getAnalytics(value: string, event: ActionEvent): AnalyticsEvent {
    return {
      event: "EventGeneric",
      EventAction: "",
      EventCat: "",
      EventLibelle: value,
      EventName: event.params.eventname,
    }
  }
}
