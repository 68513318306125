import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static readonly values = {
        siteKey: {
            type: String
        },
        fieldName: {
            type: String
        },
    }
    static readonly targets = ["widget"]

    readonly siteKeyValue: string
    readonly fieldNameValue: string
    readonly widgetTarget: HTMLElement

    public async start(): Promise<void> {
        const {  WidgetInstance, localizations } = await import('friendly-challenge');

        const options = {
            sitekey: this.siteKeyValue,
            language: localizations.fr,
            solutionFieldName: this.fieldNameValue,
            doneCallback: this.doneCallback.bind(this)
        }
        const widget = new WidgetInstance(this.widgetTarget, options);

        widget.start()
    }

    public doneCallback() {
        this.dispatch("done")
    }
}