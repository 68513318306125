import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static values = {
    url: String,
    externalUrl: String,
    timeout: { type: Number, default: 3000 },
  }
  private urlValue: string
  private externalUrlValue: string
  private timeoutValue: number

  redirectTo(): void {
    if (this.urlValue) {
      setTimeout(() => {
        window.location.href = this.urlValue
      }, this.timeoutValue)
    }
  }

  openInNewTab(): void {
    if (this.externalUrlValue) {
      window.open(this.externalUrlValue, "_blank", "noopener")
    }
  }
}
