import { ActionEvent, Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    max: Number,
  }
  readonly maxValue: number

  static classes = ["hidden"]
  readonly hiddenClass: string

  static targets = ["item", "index", "previousButton", "nextButton"]
  readonly itemTargets: HTMLElement[]
  readonly indexTarget: HTMLElement
  readonly previousButtonTarget: HTMLElement
  readonly nextButtonTarget: HTMLElement

  #currentIndex: number = 0

  open(event: ActionEvent) {
    this.#currentIndex = event.params.index
    this.#moveToIndex()
  }

  previous() {
    if (!this.previousButtonTarget.classList.contains(this.hiddenClass)) {
      this.#currentIndex--
      this.#moveToIndex()
    }
  }
  next() {
    if (!this.nextButtonTarget.classList.contains(this.hiddenClass)) {
      this.#currentIndex++
      this.#moveToIndex()
    }
  }

  #moveToIndex() {
    this.itemTargets.forEach((item) => {
      item.classList.add(this.hiddenClass)
    })
    this.indexTarget.innerText = String(this.#currentIndex + 1)
    this.itemTargets[this.#currentIndex].classList.remove(this.hiddenClass)
    this.#displayBtn()
  }

  #displayBtn() {
    this.previousButtonTarget.classList.add(this.hiddenClass)
    this.nextButtonTarget.classList.add(this.hiddenClass)
    if (this.#currentIndex !== 0) {
      this.previousButtonTarget.classList.remove(this.hiddenClass)
    }
    if (this.#currentIndex !== this.maxValue - 1) {
      this.nextButtonTarget.classList.remove(this.hiddenClass)
    }
  }
}
