import { ActionEvent, Controller } from "@hotwired/stimulus"

export default class extends Controller {
  notify(event: ActionEvent): void {
    window.notify(
      {
        Title: event.params.title,
        Icon: event.params.icon,
        Message: event.params.message,
      },
      true,
    )
  }
}
