export default {
  LOCALE: "fr-FR",
  CURRENCY: {
    EURO: "EUR",
  },
  DIGITS: {
    NONE: 0,
    TWO: 2,
  },
}
