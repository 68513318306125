import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  navigationChars = [
    "Backspace",
    "ArrowUp",
    "ArrowRight",
    "ArrowDown",
    "ArrowLeft",
    "Tab",
    "PageDown",
    "PageUp",
    "Home",
    "End",
    "Delete",
    "F5",
    "F12",
  ]

  onlyDouble(e: KeyboardEvent) {
    const allowedChars = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      ",",
      " ",
      ...this.navigationChars,
    ]
    if (!allowedChars.includes(e.key)) {
      e.preventDefault()
    }

    const inputTarget = <HTMLInputElement>e.target
    const commaIsPossible =
      inputTarget.value.length > 0 && inputTarget.value.indexOf(",") === -1

    if (e.key === "," && !commaIsPossible) {
      e.preventDefault()
    }

    if (e.key === "." && commaIsPossible) {
      this.replacePointByComma(e)
    }
  }

  private replacePointByComma = (e: KeyboardEvent): void => {
    const inputTarget = <HTMLInputElement>e.target
    if (inputTarget) {
      const cursor = inputTarget.selectionEnd + 1

      // I change value before display
      let newValueArray = inputTarget.value.split("")
      newValueArray.splice(inputTarget.selectionStart, 0, ",")
      inputTarget.value = newValueArray.join("")

      // After replace value, puts the cursor just after the comma on input
      inputTarget.setSelectionRange(cursor, cursor)
    }
  }
}
