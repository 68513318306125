/* It's mandatory to use this function to add script elements to the HTML. Script sources won't be loaded otherwise. */
export function createScriptElement(container: HTMLElement, html: string) {
  container.innerHTML = html

  const scriptElement = container.querySelector("script")
  if (scriptElement) {
    const newScriptElement = document.createElement("script")

    Array.from(scriptElement.attributes).forEach((attr) => {
      newScriptElement.setAttribute(attr.name, attr.value)
    })

    const scriptText = document.createTextNode(scriptElement.innerHTML)
    newScriptElement.appendChild(scriptText)

    scriptElement.replaceWith(newScriptElement)
  }
}

export function escapeHtml(text: string) {
  return text.replaceAll("<", "&lt;").replaceAll(">", "&gt;")
}

export function copyAttributesAndChildren(source: Element, { into }: { into: Element }): void {
  for (const attribute of source.attributes) {
    into.setAttribute(attribute.name, attribute.value)
  }
  // We avoid using slow serialisation to copy the content
  // cf https://jsbench.me/vcleydoz3u/1
  replaceChildren(into, ...source.childNodes)
}

// Element.replaceChildren is only supported since Safari 14 so we emulate it for now
function replaceChildren(element: Element, ...nodes: Node[]): void {
  element.innerHTML = ""
  element.append(...nodes)
}

export function createElementFromHTML(htmlString: string): ChildNode {
  const template = document.createElement("template")
  template.innerHTML = htmlString.trim()

  return template.content.firstChild
}
