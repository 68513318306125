// ⚠️ Please keep in sync with tailwind.config.js
//
// The media queries information is duplicated
// Once https://hellowork.atlassian.net/browse/SE-6997 is done,
// we may try to remove this duplication
const screens = {
  sm: "641px",
  md: "768px",
  lg: "951px",
  xl: "1025px",
}

const mediaQueries: { [key: string]: string } = {}

for (const [label, minWidth] of Object.entries(screens)) {
  mediaQueries[label] = `(min-width: ${minWidth})`
}

export default mediaQueries
