/*
 ** ⚠️ This controller is temporary code. ⚠️
 * Its objective is to manage the particular case of pages completely managed by Lastack: onboarding and the registration page.
 * It will disappear with the migration of these pages. The information can then be passed through the ViewModel
 */

import { Controller } from "@hotwired/stimulus"

export const ACCOUNT_CREATION_SUCCESS: string = "accountCreationSuccess"

export default class extends Controller {
  static targets = ["innerText"]
  readonly innerTextTarget: HTMLSpanElement

  connect(): void {
    document.addEventListener(
      ACCOUNT_CREATION_SUCCESS,
      (event: CustomEvent) => {
        this.innerTextTarget.innerText = event.detail.userEmail
      },
    )
  }
}
