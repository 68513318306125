import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["element"]
  readonly elementTargets: HTMLElement[]

  #getElementIndex(): number {
    return this.elementTargets.findIndex((elementTarget) => {
      return elementTarget === document.activeElement
    })
  }

  focusPreviousElement(event: KeyboardEvent): void {
    event.preventDefault()

    const elementIndex = this.#getElementIndex()

    if (elementIndex > 0) {
      this.elementTargets[elementIndex - 1].focus()
    }
  }

  focusNextElement(event: KeyboardEvent): void {
    event.preventDefault()

    const elementIndex = this.#getElementIndex()

    if (elementIndex < this.elementTargets.length - 1) {
      this.elementTargets[elementIndex + 1].focus()
    }
  }
}
