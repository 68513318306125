import { Controller } from "@hotwired/stimulus"
import { getAccountData } from "@models/account-data"
import { getOffer, saveOfferInSession } from "@utils/user_offer_storage"

export default class extends Controller<HTMLElement> {
  static values = {
    offerId: String,
    target: String,
  }
  readonly targetValue: string
  readonly offerIdValue: string

  async save(event: any): Promise<void> {
    const data = await getAccountData()
    if (!data.IsAuthenticated) {
      const offer = getOffer(this.offerIdValue)
      if (offer) {
        offer[this.targetValue] = event.detail ?? event.target.value
        saveOfferInSession(offer)
      }
    }
  }
}
