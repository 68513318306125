import { Controller } from "@hotwired/stimulus"
import { InputTypes } from "@utils/input_types"

export default class extends Controller {
  static values = {
    placeholder: String,
  }
  readonly placeholderValue: string

  static targets = ["from", "to"]
  readonly fromTargets: Array<HTMLInputElement>
  readonly toTarget: HTMLElement

  static classes = ["empty"]
  readonly emptyClasses: Array<string>

  append(): void {
    const values: Array<string> = []
    this.fromTargets.forEach((target: HTMLInputElement) => {
      if (target.type === InputTypes.INPUT_TYPE_CHECKBOX) {
        if (target.checked) {
          const inputLabels = target.labels
          if (inputLabels.length) {
            values.push(inputLabels[0].textContent.trim())
          }
        }
      } else if (target.type === InputTypes.INPUT_TYPE_TEXT) {
        if (target.value) {
          values.push(target.value)
        }
      }
    })

    if (values.length) {
      this.toTarget.innerText = values.join(", ")
      this.toTarget.classList.remove(...this.emptyClasses)
    } else {
      this.toTarget.innerText = this.placeholderValue
      this.toTarget.classList.add(...this.emptyClasses)
    }
  }
}
