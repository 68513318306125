import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field"]
  readonly fieldTarget: HTMLInputElement

  clear(): void {
    this.fieldTarget.value = ""
  }
}
