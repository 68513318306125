import { ActionEvent, Controller } from "@hotwired/stimulus"
import { getItemFromLocalStorage, setItemInLocalStorage } from "@utils/local_storage/local_storage.utils"

const MAX_STORED_IDS = 50

export default class extends Controller {
  static targets = ["item"]
  readonly itemTargets: HTMLElement[]

  static classes = ["flag"]
  declare readonly flagClass: string

  private getStoredIds(key: string): number[] {
    const storedIds = getItemFromLocalStorage(key)
    return storedIds ? JSON.parse(storedIds) : []
  }

  save({ params: { localStorageKey, id } }: ActionEvent): void {
    const ids = this.getStoredIds(localStorageKey)

    if (ids.length >= MAX_STORED_IDS) {
      ids.shift()
    }

    if (id && !ids.includes(id)) {
      ids.push(id)
    }

    setItemInLocalStorage(localStorageKey, JSON.stringify(ids))
  }

  applyFlagClass({ params: { localStorageKey, itemId } }: ActionEvent): void {
    const localStorageItemIds = this.getStoredIds(localStorageKey)

    this.itemTargets.forEach((item) => {
      let itemId = parseInt(item.getAttribute("data-id-storage-item-id"))
      if (localStorageItemIds.includes(itemId)) {
        item.classList.add(this.flagClass)
      }
    })
  }
}
