import {
  getItemFromSessionStorage,
  setItemInSessionStorage,
} from "./session_storage/session_storage.utils"

export const HW_FORM_USER_DATA = "hw_form_user_data"

export interface FileInfo {
  fileName: string
  uploadKey: string
  uploadType: string
}

export interface StringIndex {
  [key: string]: any
}

export interface FormUserData extends StringIndex {
  id: string
  coverLetter: string
  uploader: FileInfo
  toString: () => string
}

export function getOffer(id: string): FormUserData {
  const defaultOffer = getEmptyUserData(id)
  const rawStorage = getItemFromSessionStorage(HW_FORM_USER_DATA)
  if (!rawStorage) return defaultOffer
  try {
    const storage: FormUserData[] = JSON.parse(rawStorage)
    const offerFounded = storage.find((offer) => offer.id === id)
    return offerFounded ?? defaultOffer
  } catch (error) {
    return defaultOffer
  }
}

export function getEmptyUserData(id: string): FormUserData {
  return {
    id,
    coverLetter: "",
    uploader: {
      fileName: "",
      uploadKey: "",
      uploadType: "",
    },
  }
}

export function saveOfferInSession(offer: FormUserData): void {
  const rawStorage = getItemFromSessionStorage(HW_FORM_USER_DATA)
  if (!rawStorage) {
    setItemInSessionStorage(HW_FORM_USER_DATA, JSON.stringify([offer]))
  } else {
    const storage: FormUserData[] = JSON.parse(rawStorage)
    let isNew = true
    storage.forEach((offerStorage, index, store) => {
      if (offer.id === offerStorage.id) {
        store[index] = offer
        isNew = false
      }
    })
    if (isNew) storage.push(offer)
    setItemInSessionStorage(HW_FORM_USER_DATA, JSON.stringify(storage))
  }
}

export function getOfferInSession(offerId: string): FormUserData {
  const rawStorage = getItemFromSessionStorage(HW_FORM_USER_DATA)
  if (!rawStorage) return null
  const storage: FormUserData[] = JSON.parse(rawStorage)
  return storage.find((offer) => offer.id === offerId)
}

export function getFormUserDataFromSession(
  selector = "[data-save-field-offer-id-value]",
) {
  const target = document.querySelector<HTMLElement>(selector)
  const idOffer = target?.dataset.saveFieldOfferIdValue
  if (idOffer) {
    const offer = getOfferInSession(idOffer)
    if (offer) return offer
  }
}

export function hasFileInSession(offer: FormUserData, key: string) {
  return offer.uploader?.uploadKey === key
}
