import { ActionEvent, Controller } from "@hotwired/stimulus"
import { AnalyticsEvent } from "@models/analytics-event"

export default class extends Controller {
  static values = {
    type: String,
    event: {
      EventCat: String,
      EventAction: String,
      EventLibelle: String,
      EventName: String,
    },
    externalLibelle: String,
  }
  readonly typeValue: string
  readonly hasTypeValue: boolean
  readonly eventValue: AnalyticsEvent
  //if a shared component agnostic for its type of page needs to send an event with the type of page (or category, subject...) it may use [externalLibelleValue] to retrieve that info from
  // window.dataLayer first entry
  readonly externalLibelleValue: string
  readonly hasExternalLibelleValue: boolean

  /**
   * @deprecated Use push() instead.
   */
  send(): void {
    const eventPush: AnalyticsEvent = {
      event: this.hasTypeValue ? this.typeValue : "EventGeneric",
      EventCat: this.eventValue.EventCat,
      EventAction: this.eventValue.EventAction,
      EventLibelle: this.hasExternalLibelleValue
        ? "[" + window.dataLayer[0][this.externalLibelleValue] + "] - " + this.eventValue.EventLibelle
        : this.eventValue.EventLibelle,
    }
    if (this.eventValue.EventName) {
      eventPush.EventName = this.eventValue.EventName
    }
    if (this.eventValue.idCandidat) {
      eventPush.idCandidat = this.eventValue.idCandidat
    }
    if (this.eventValue.isNewAccount != null) {
      eventPush.isNewAccount = this.eventValue.isNewAccount
    }
    if (this.eventValue.cta != null) {
      eventPush.cta = this.eventValue.cta
    }
    if (this.eventValue.wording != null) {
      eventPush.wording = this.eventValue.wording
    }
    window.dataLayer.push(eventPush)
  }

  pushCustomEvent(event: CustomEvent): void {
    if (event instanceof CustomEvent && event.detail) {
      window.dataLayer.push(event.detail)
    }
  }

  push(event: ActionEvent): void {
    if (event.params.values) {
      window.dataLayer.push(event.params.values)
    }
  }
}
