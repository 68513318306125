import { Controller } from "@hotwired/stimulus"
import { platformForInstallableApp } from "@utils/user_agent/user_agent.utils"

export default class extends Controller {
  static targets = ["android", "ios"]

  androidTargetConnected(target: Element): void {
    if (platformForInstallableApp() == "ios") {
      target.remove()
    }
  }

  iosTargetConnected(target: Element): void {
    if (platformForInstallableApp() == "android") {
      target.remove()
    }
  }
}
