import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["animation"]
  readonly animationClasses: string[]

  remove(): void {
    this.animationClasses.length > 0 ? this.animateBeforeRemove() : this.element.remove()
  }

  /**
   * We launch animation with classes and on Animation End remove elements after
   * @private
   */
  private animateBeforeRemove() {
    this.element.addEventListener("animationend", () => {
      this.element.remove()
    })

    this.element.classList.add(...this.animationClasses)
  }
}
