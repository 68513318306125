import { Controller } from "@hotwired/stimulus"

/*
    This controller remove stuck classes when the stickable element (this.element) is 100% visible in the viewport, and 
    add them when at least 1px of the stickable element is out of the viewport. It relies on IntersectionObserver API.

    To make it work, we need to set a stickable behavior on the element, and a negative top position, so that the element
    will have a little part out of the viewport when stuck: class="tw-sticky -tw-top-px" 
*/

export default class extends Controller {
  private stickyObserver: IntersectionObserver

  static classes = ["stuck"]
  readonly stuckClasses: string[]

  connect(): void {
    this.stickyObserver = new IntersectionObserver(
      (entries) => {
        entries[0].isIntersecting
          ? this.#removeStuckClasses()
          : this.#addStuckClasses()
      },
      {
        threshold: 1.0,
      },
    )
    this.stickyObserver.observe(this.element)
  }

  disconnect(): void {
    this.stickyObserver.disconnect()
    delete this.stickyObserver
  }

  #removeStuckClasses(): void {
    this.element.classList.remove(...this.stuckClasses)
  }

  #addStuckClasses(): void {
    this.element.classList.add(...this.stuckClasses)
  }
}
