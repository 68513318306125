import { Controller } from "@hotwired/stimulus"

// simple rebound controller which intends to hide a form or any element targeted and show another div with a success message

export default class extends Controller {
  static targets = ["toHide", "toShow"]
  readonly toHideTarget: HTMLElement
  readonly toShowTarget: HTMLElement
  static classes = ["hide"]
  readonly hideClass: string

  hideAndDisplay(): void {
    this.toHideTarget.classList.add(this.hideClass)
    this.toShowTarget.classList.remove(this.hideClass)
  }
}
