import { Controller } from "@hotwired/stimulus"

export default class CheckboxTreeController extends Controller {
  static targets: string[] = ["parent", "child"]
  readonly parentTarget: HTMLInputElement
  readonly hasParentTarget : boolean
  readonly childTargets: HTMLInputElement[]

  parentTargetConnected(): void {
    this.checkParentIfNeeded()
  }

  toggleAll(): void {
    this.childTargets.forEach((child) => {
      this.parentTarget.checked
        ? child.checked = true
        : child.checked = false
    })
  }

  checkParentIfNeeded(): void {
    if (this.hasParentTarget) {
      const childrendChecked = this.#getChildrenChecked(this.childTargets)
      if (this.childTargets.length === childrendChecked.length) {
        this.parentTarget.checked = true
      } else {
        this.parentTarget.checked = false
      }
    }
  }
  #getChildrenChecked(checkboxList: HTMLInputElement[]): HTMLInputElement[] {
    return checkboxList.filter((checkbox) => checkbox.checked)
  }
}
