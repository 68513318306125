import {hw} from "../helpers/paths";

export abstract class ALERT {
  static readonly OK: number = 0;
  static readonly CONFIRM: number = 1;
  static readonly ERROR: number = 2;
  static readonly WARNING: number = 3;
}

function getLevel (level: ALERT) {
    switch (level) {
      case ALERT.OK:
        return "ok";
      case ALERT.CONFIRM:
        return "confirm";
      case ALERT.ERROR:
        return "error";
      case ALERT.WARNING:
        return "confirm";
      default:
        return "ok";
    }
}

function getIcon (sticky:boolean, action:string): string {
    let icon = ""

    if (sticky) {
        if (action === 'error') {
          icon = 'error';
        } else if (action === 'confirm') {
          icon = 'info';
        } else {
          icon = 'success';
        }
    } else {
        if(action === 'error') {
          icon = 'error';
        } else {
          icon = 'success';
        }
    }

    return icon
      ? hw.getBaseRessourcesUrl() + "/img/svg/badges/" + icon + ".svg"
      : "";
}

// --------------------------------------------------

export {
    getLevel,
    getIcon
}