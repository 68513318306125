import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  public confetti: (options: unknown) => object

  private importConfetti() {
    return new Promise((resolve) => {
      if (this.confetti) {
        resolve(this.confetti)
      } else {
        import("canvas-confetti").then((e) => {
          this.confetti = e.default
          resolve(this.confetti)
        })
      }
    })
  }

  start() {
    this.importConfetti().then(() => {
      this.confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
        gravity: 0.2,
        startVelocity: 35,
      })
    })
  }
}
