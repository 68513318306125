import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["requiredCheckOrRadio", "submit"]
  readonly requiredCheckOrRadioTargets: HTMLInputElement[]
  readonly hasRequiredCheckOrRadioTarget: boolean
  readonly submitTarget: HTMLButtonElement

  formChanged(): void {
    let valid = false
    if (this.hasRequiredCheckOrRadioTarget) {
      this.requiredCheckOrRadioTargets.forEach((input: HTMLInputElement) => {
        if (input.checked) valid = true
      })
      if (valid) {
        this.valid()
      }
    }
  }
  private valid(): void {
    this.submitTarget.removeAttribute("disabled")
  }
}
