const HOURLY_BY_MONTH = 151.67
const MONTH_BY_YEAR = 12

export function hourlyToMonthly(
  salary: number,
  hourlyByMonth: number = HOURLY_BY_MONTH,
): number {
  return Math.round(salary * hourlyByMonth)
}

export function monthlyToHourly(
  salary: number,
  hourlyByMonth: number = HOURLY_BY_MONTH,
): number {
  return parseFloat((salary / hourlyByMonth).toFixed(2))
}

export function monthlyToAnnual(
  salary: number,
  monthByYear: number = MONTH_BY_YEAR,
): number {
  return Math.round(salary * monthByYear)
}

export function annualToMonthly(
  salary: number,
  monthByYear: number = MONTH_BY_YEAR,
): number {
  return Math.round(salary / monthByYear)
}
