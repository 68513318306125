import { Controller } from "@hotwired/stimulus"
import { resizeMainContainer } from "../../../components/shared/helpers/html-utils"
import { numberWithSpace } from "../../../components/shared/helpers/number"

export default class extends Controller {
  static targets = [
    "outputAnnual",
    "outputMonth",
    "outputHour",
    "range",
    "enableSalary",
    "style",
    "notif",
    "select",
  ]
  readonly outputAnnualTarget: HTMLElement
  readonly outputMonthTarget: HTMLElement
  readonly outputHourTarget: HTMLElement
  readonly rangeTarget: HTMLInputElement
  readonly enableSalaryTarget: HTMLInputElement
  readonly hasEnableSalaryTarget: boolean
  readonly styleTarget: HTMLElement
  readonly notifTarget: HTMLElement
  readonly selectTarget: HTMLElement
  static classes = ["hide"]
  readonly hideClass: string

  connect(): void {
    if (this.hasEnableSalaryTarget && this.isEnabled(this.enableSalaryTarget)) {
      this.rangeTarget.removeAttribute("disabled")
      this.notifTarget.classList.remove(this.hideClass)
      this.notifTarget.innerText = "1"
    }
    this.updateValue()
  }

  updateValue(): void {
    const value = parseInt(this.rangeTarget.value)
    this.handleTrackStyle(this.rangeTarget, value)

    //ouput value
    this.outputAnnualTarget.innerHTML = numberWithSpace(value)
    const month = this.calcMonth(value)
    this.outputMonthTarget.innerHTML = numberWithSpace(Math.round(month))
    this.outputHourTarget.innerHTML = this.calcHour(month).toFixed(2).toString()
  }
  calcMonth(annual: number): number {
    return annual / 12
  }
  calcHour(month: number): number {
    return month / 151.67
  }
  callEngine(): void {
    const value = this.rangeTarget.value
    const lis = this.selectTarget.querySelectorAll("li")
    for (const li of lis) {
      if (li.dataset.value === value) {
        li.querySelector("label").click()
      }
    }
  }
  toggleEnable(evt: Event): void {
    evt.preventDefault()
    evt.stopImmediatePropagation()
    if (this.isEnabled(<HTMLInputElement>evt.currentTarget)) {
      this.rangeTarget.removeAttribute("disabled")
      this.handleTrackStyle(this.rangeTarget, parseInt(this.rangeTarget.value))
      this.notifTarget.classList.remove(this.hideClass)
      this.notifTarget.innerText = "1"
      this.callEngine()
    } else {
      this.disable()
      document
        .getElementById("msa-tag")
        .querySelector<HTMLElement>("svg")
        .dispatchEvent(new Event("click"))
    }
  }
  uncheck(): void {
    this.enableSalaryTarget.checked = false
    this.disable()
  }
  disable(): void {
    this.rangeTarget.setAttribute("disabled", "true")
    this.notifTarget.classList.add(this.hideClass)
    this.notifTarget.innerText = ""
  }
  isEnabled(input: HTMLInputElement): boolean {
    return input.checked
  }
  // there is no other way to style the track progess in Chrome or Safari :-/
  handleTrackStyle(slider: HTMLInputElement, value: number): void {
    if (!this.rangeTarget.hasAttribute("disabled")) {
      const gradValue = Math.round(
        (value / parseInt(slider.getAttribute("max"))) * 1 * 100,
      )
      const grad =
        "linear-gradient(90deg,#DFDFDF " +
        gradValue +
        "%,rgb(0, 0, 0)" +
        (gradValue + 1) +
        "%)"
      const selector =
        this.rangeTarget.className +
        ":not(:disabled)::-webkit-slider-runnable-track"
      this.styleTarget.textContent =
        "." + selector + "{background: " + grad + ";} "
    }
  }
  resize(): void {
    if (window.innerWidth > 767) {
      // when we open or close facets we need to apply a min-height property on main container to avoid facets container from going over footer
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"))
      }, 100)
      resizeMainContainer()
    }
  }
}
