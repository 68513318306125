import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["origin", "destination"]
  readonly originTarget: HTMLElement
  readonly destinationTarget: HTMLElement
  static values = {
    children: {
      minLenght: Number,
      eq: Number,
    },
  }
  readonly childrenValue: {
    minLenght: number
    eq: number
  }

  teleportAfterTargetDestination(): void {
    this.destinationTarget.after(this.originTarget)
  }
  teleportAfterTargetDestinationChildren(): void {
    if (this.destinationTarget.children.length > this.childrenValue.minLenght) {
      this.destinationTarget.children[this.childrenValue.eq].after(
        this.originTarget,
      )
    }
  }
}
