// ⚠️ Do not use. Only temporary until https://hellowork.atlassian.net/browse/SE-7909 is closed. Remove afterwards.

import { Controller } from "@hotwired/stimulus"

export default class extends Controller<HTMLInputElement> {
  static classes = ["toggled"]
  readonly toggledClasses: string[]

  public toggleClass(): void {
    const header = document.querySelector("header")
    if (this.element.checked) {
      header.classList.add(...this.toggledClasses)
    } else {
      header.classList.remove(...this.toggledClasses)
    }
  }
}
