import { Controller } from "@hotwired/stimulus"

export default class ScrollIntoViewController extends Controller {
  static values = {
    element: String,
    block: {
      type: String,
      default: "start",
    },
  }
  readonly elementValue: string
  readonly blockValue: string

  scroll() {
    const element = document.querySelector(this.elementValue)
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: <ScrollLogicalPosition>this.blockValue,
      })
    }
  }
}
