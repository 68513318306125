export function getItemFromLocalStorage(key: string): string {
  try {
    return localStorage.getItem(key)
  } catch (e) {
    return null
  }
}

export function setItemInLocalStorage(key: string, value: string): void {
  try {
    localStorage.setItem(key, value)
  } catch (e) {}
}

export function removeItemFromLocalStorage(key: string): void {
  try {
    localStorage.removeItem(key)
  } catch (e) {}
}
