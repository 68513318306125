import { Controller } from "@hotwired/stimulus"
import { hasCookie, setCookieWithDurationInDays, getCookie } from "@utils/cookie_storage/cookie_storage.utils"

export default class extends Controller {
    static values = {
        name: String,
        val: String,
        duration: {
            type: Number,
            default: 1,
        },
    }

    readonly nameValue: string
    readonly hasNameValue: boolean

    readonly valValue: string
    readonly hasValValue: boolean

    private durationValue: number

    setCookie(): void {
        setCookieWithDurationInDays(this.nameValue, this.valValue, this.durationValue)
    }

    hasCookie():void {
        if (hasCookie(this.nameValue) && getCookie(this.nameValue) == this.valValue) {
            this.dispatch("hasCookie")
        }
    }
}