import { Controller } from "@hotwired/stimulus"

export const CONVERSION_BOX_SESSION_TOKEN_PREFIX = "_hw_conversion_box_already_displayed_"

export default class extends Controller {
  static values = {
    type: String,
  }
  readonly typeValue: string

  checkIfDisplayable(): void {
    const sessionToken = CONVERSION_BOX_SESSION_TOKEN_PREFIX + this.typeValue

    // Display box only one time per session
    if (!sessionStorage.getItem(sessionToken)) {
      sessionStorage.setItem(sessionToken, "true")

      this.dispatch("display")
    }
  }
}
