import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    customUrl: String,
  }
  readonly customUrlValue: string

  static targets = ["scriptElement", "customFrame"]
  readonly scriptElementTarget: HTMLElement
  readonly customFrameTarget: HTMLElement

  importOneTapScript(marketingCookiesChanged: CustomEvent): void {
    if (marketingCookiesChanged?.detail.hasAccepted) {
      this.scriptElementTarget.setAttribute("src", "https://accounts.google.com/gsi/client")
    } else {
      this.importCustomOneTap()
    }
  }

  importCustomOneTap(): void {
    this.customFrameTarget.setAttribute("src", this.customUrlValue)
  }
}
