import { ActionEvent, Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["toggled"]
  readonly toggledClasses: Array<string>

  toggle(event: ActionEvent): void {
    this.toggledClasses.forEach((toggledClass: string) => {
      if (typeof event.params.force !== "undefined") {
        document.body.classList.toggle(toggledClass, event.params.force)
      } else {
        document.body.classList.toggle(toggledClass)
      }
    })
  }
  add(): void {
    this.toggledClasses.forEach((toggledClass: string) => {
      document.body.classList.add(toggledClass)
    })
  }

  remove(): void {
    this.toggledClasses.forEach((toggledClass: string) => {
      document.body.classList.remove(toggledClass)
    })
  }
}
