import { Controller } from "@hotwired/stimulus"

/**
 * This controller is used when there is checkboxes required from a form
 * We check if one is selected then they are not required anymore because one is enough
 */
export default class extends Controller {
  static targets = ["parent"]
  readonly parentTarget: HTMLDivElement

  connect(): void {
    this.dispatch("connected")
  }

  atLeastOneIsChecked() {
    const checkboxes = Array.from(
      this.parentTarget.querySelectorAll('input[type="checkbox"]'),
    ) as HTMLInputElement[]

    checkboxes.find((checkbox: HTMLInputElement) => checkbox.checked)
      ? this.checkRequired(checkboxes, false)
      : this.checkRequired(checkboxes, true)
  }

  private checkRequired(checkboxes: HTMLInputElement[], required: boolean) {
    checkboxes.forEach(
      (checkbox: HTMLInputElement) => (checkbox.required = required),
    )
  }
}
