interface Rect {
    top: number,
    left: number,
    bottom: number,
    right: number
}
interface Offset {
     x: number,
     y: number,
     width : number,
     top : number,
     height : number,
     left : number,
     bottom: number,
     right: number
}
interface Coordonate {
    x: number,
    y: number
}

export interface EventExtend extends Event {
    x: number,
    y: number
}

export function createElementsFromHTML(htmlString: string): NodeListOf<ChildNode> {
    const template = document.createElement("template");
    template.innerHTML = htmlString.trim();

    return template.content.childNodes; 
}

export function isEventOn(event: EventExtend, element: any): boolean {
     if (event.target == element) {
        return true;
    }
    const rect: Rect = {top : 0, left : 0, bottom : 0, right : 0};
    const elementOffset = getOffset(element);
    rect.top = elementOffset.top;
    rect.left = elementOffset.left;
    rect.right = rect.left + element.offsetWidth;
    rect.bottom = rect.top + element.offsetHeight;
    return isOn({"x" : event.x, "y" : event.y}, rect);
} 

export function getOffset(el:any):Offset {
    if (el) {
        return el.getBoundingClientRect();
    }
    return {
        x: undefined,
        y: undefined,
        width : 0,
        top : 0,
        height : 0,
        left : 0,
        right: undefined,
        bottom: undefined
    }
}

function isOn(coord: Coordonate, rect: Rect): boolean {
    return coord.x >= rect.left && coord.x <= rect.right && coord.y >= rect.top && coord.y <= rect.bottom;
}

export function findFilteredParent(element: any, filter: any, stop?: any ): any {
    if (!filter) return element;
    
    let result: any;
    let prt = element;

    while (!result && prt) {
        if (filter(prt)) {
            result = prt;
            prt = false;
        } else {
            prt = prt.parentNode;

            // Check the stop condition in order to break the loop
            if (prt && stop && stop(element)) {
                prt = false;
            }
        }
    }
    
    return result;
}

export function isParent(element:any, parent:any) {
    return findFilteredParent(element, function(filterable:any) {return filterable === parent})
}

export function resizeMainContainer():void {
    const facets = document.querySelector("[data-facets-search]");
    const container = document.querySelector<HTMLElement>("section.serp");
    if(facets && container) {
        const facetsHeight = facets.clientHeight;
        let alertsideHeight = 0;
        if(document.querySelector(".side-alert") && !document.querySelector(".side-alert").parentElement.classList.contains("side-box-hidden")) {
            alertsideHeight = 400;
        }
        container.style.minHeight = (facetsHeight + alertsideHeight + 140) + "px";
    }
}
