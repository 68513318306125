import { endOfDayInNDays } from "../dates"

interface CookieConfiguration {
  uuid: string
  statistics: boolean
  marketing: boolean
}

export const MARKETING_COOKIE = "hw-cc-first-party"

/**
 * @param daysBeforeExpiration Cookie will expire at the end of the specified day.
 * Ex: 0 expires at the end of today
 *     1 expires at the end of tomorrow
 */
export function setCookieWithDurationInDays(
  name: string,
  value: string,
  daysBeforeExpiration?: number,
): void {
  const expires_prop = isNaN(daysBeforeExpiration)
    ? ""
    : "expires=" + endOfDayInNDays(daysBeforeExpiration).toUTCString() + ";"

  document.cookie = `${name}=${value};${expires_prop};path=/`
}

export function getCookie(name: string): string | null {
  const cookies = decodeURIComponent(document.cookie)
  const prefix = name + "="
  for (const entry of cookies.split(";").map((entry) => entry.trim())) {
    if (entry.startsWith(prefix)) {
      return entry.slice(prefix.length)
    }
  }
  return null
}

export function hasCookie(name: string): boolean {
  return getCookie(name) !== null
}

export function removeCookie(name: string): void {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/"
}

export function setCookieWithDurationInMinutes(
  name: string,
  value: string,
  minutesBeforeExpiration: number,
): void {
  const expires = new Date()
  expires.setMinutes(
    expires.getMinutes() -
      expires.getTimezoneOffset() +
      minutesBeforeExpiration,
  )

  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`
}

export function hasAcceptedMarketingCookies(): boolean {
  let hasAccepted = false
  const cookieConfigurationJson = getCookie(MARKETING_COOKIE)
  if (cookieConfigurationJson) {
    try {
      const cookieConfiguration: CookieConfiguration = JSON.parse(
        cookieConfigurationJson,
      )
      hasAccepted = !!cookieConfiguration.marketing
    } catch (error) {}
  }
  return hasAccepted
}
