import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["saved","destination"]
    declare readonly destinationTarget: HTMLElement
    declare readonly savedTarget: HTMLElement
    private _elementSaved: HTMLElement;
    private _hasSaved = false;
    save() {
        if(this.savedTarget) {
            this._elementSaved = (this.savedTarget.cloneNode(true) as HTMLElement)
            this._hasSaved = true
        }
    }
    replace() {
        if (this._hasSaved) {
            this.destinationTarget.replaceWith(this._elementSaved)
        }
    }
}
