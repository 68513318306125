export type NotificationLevel = "ok" | "confirm" | "error"

export interface NotificationData {
  action: NotificationLevel
  title: string
  messages: string[]
  sticky?: boolean
  vanish?: boolean
}

let initOk = false
const notificationBuffer: NotificationData[] = []

export function notify(level: NotificationLevel, message: string) {
  const data: NotificationData = {
    action: level,
    title: message,
    messages: [""],
    sticky: true,
  }
  if (initOk) {
    PubSub.publish("notify", data)
  } else {
    notificationBuffer.push(data)
  }
}

function onNotifyInit(): void {
  initOk = true
  for (let data of notificationBuffer) {
    PubSub.publish("notify", data)
  }
}

PubSub.subscribeOnce("connected.notifications", onNotifyInit)
