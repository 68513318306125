import { Controller } from "@hotwired/stimulus"
import { isParent } from "../../components/shared/helpers/html-utils"
import {
  hasCookie,
  setCookieWithDurationInDays,
} from "@utils/cookie_storage/cookie_storage.utils"

export default class extends Controller {
  static targets = ["trigger", "modal"]
  readonly modalTarget: HTMLElement
  readonly triggerTarget: HTMLElement
  readonly hasTriggerTarget: boolean
  readonly hasModalTarget: boolean

  //you need hide classes to prevent modal from being displayed at load
  //use hide classes to give your modal an opening & closing effect
  //you should use tailwindCSS classes
  static classes = ["hide"]
  readonly hideClasses: [string]

  static values = {
    cookie: String,
  }
  readonly cookieValue: string

  clickOutsideModal(event: Event): void {
    if (
      event.target !== this.modalTarget &&
      !isParent(<HTMLElement>event.target, this.modalTarget)
    ) {
      this.close()
    }
  }
  open(event: Event): void {
    if (this.hasModalTarget) {
      setTimeout(() => {
        event.stopImmediatePropagation()
        this.modalTarget.classList.remove(...this.hideClasses)
        this.triggerTarget.setAttribute("disabled", "true")
      }, 150)
    }
  }
  close(): void {
    this.modalTarget.classList.add(...this.hideClasses)
    if (this.hasTriggerTarget) {
      this.triggerTarget.removeAttribute("disabled")
    }
  }
  openIfNoCookie(): void {
    if (!hasCookie(this.cookieValue)) {
      this.modalTarget.classList.remove(...this.hideClasses)
      setCookieWithDurationInDays(this.cookieValue, "true", 365)
    }
  }
}
