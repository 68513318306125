import { ActionEvent, Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toToggle"]
  readonly toToggleTargets: HTMLElement[]
  static classes = ["toggled"]
  readonly toggledClasses: Array<string>

  toggle(event: ActionEvent): void {
    this.toToggleTargets.forEach((toToggle: HTMLElement) => {
      this.toggledClasses.forEach((toggledClass: string) => {
        if (typeof event.params.force !== "undefined") {
          toToggle.classList.toggle(toggledClass, event.params.force)
        } else {
          toToggle.classList.toggle(toggledClass)
        }
      })
    })
  }

  singleToggle(event: ActionEvent): void {
    this.toToggleTargets.forEach((toToggle: HTMLElement) => {
      if (event.currentTarget === toToggle) {
        toToggle.classList.add(...this.toggledClasses)
      } else {
        toToggle.classList.remove(...this.toggledClasses)
      }
    })
  }

  add(): void {
    this.toToggleTargets.forEach((toToggle: HTMLElement) => {
      this.toggledClasses.forEach((toggledClass: string) => {
        toToggle.classList.toggle(toggledClass, true)
      })
    })
  }

  remove(): void {
    this.toToggleTargets.forEach((toToggle: HTMLElement) => {
      this.toggledClasses.forEach((toggledClass: string) => {
        toToggle.classList.toggle(toggledClass, false)
      })
    })
  }
}
