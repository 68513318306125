import { Controller } from "@hotwired/stimulus"
import { AccountDataInterface, getAccountData } from "@models/account-data"

export default class FromAccountDataController extends Controller {
  static values = {
    copy: String,
    into: String,
  }

  readonly copyValue: keyof AccountDataInterface
  readonly intoValue: string

  async connect(): Promise<void> {
    const data = await getAccountData()
    if (data.IsAuthenticated) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.element as any)[this.intoValue] = data[this.copyValue]
        this.dispatch("copied")
    }
  }
}
