import { Controller } from "@hotwired/stimulus"
import { toStringTime } from "@utils/time/time.utils"

export default class extends Controller {
  static values = { duration: Number }
  static readonly targets = ["countdown"]

  readonly countdownTarget: HTMLElement
  readonly durationValue: number

  private interval: NodeJS.Timeout

  disconnect() {
    clearInterval(this.interval)
  }

  start() {
    this.dispatch("started")
    let finalCountdown = this.durationValue
    // first appearance
    this.countdownTarget.textContent = toStringTime(finalCountdown)

    this.interval = setInterval(() => {
      finalCountdown--

      if (finalCountdown === 0) {
        this.finish()
      }

      this.countdownTarget.textContent = toStringTime(finalCountdown)
    }, 1000)
  }

  private finish() {
    clearInterval(this.interval)
    this.dispatch("finished")
  }
}
