import { Controller } from "@hotwired/stimulus"
import { TurboFrameRenderEvent } from "@hotwired/turbo"

//Note pour plus tard : idéalement il faudrait patcher Turbo pour que ce soit fait automatiquement sans passer par un controller, ou bien rendre ce controller plus générique. Ca peut être un sujet d'intersprint.
export default class TurboDispatchEvent extends Controller {

  turboFrameRenderCustomDispatch(event: TurboFrameRenderEvent): void {
    if ((event.target as Element) === this.element) {
      this.dispatch(this.element.id + "TurboFrameRender")
    }
  }
}
