import { ActionEvent, Controller } from "@hotwired/stimulus"

interface Directive {
  from: string
  to: string
}

export default class extends Controller {
  insertValue(event: ActionEvent) {
    event.params.from?.inputs?.forEach((directive: Directive) => {
      const from = document.getElementById(directive.from) as HTMLInputElement
      const to = document.getElementById(directive.to) as HTMLInputElement

      if (from && to) {
        to.value = from.value
      }
    })
  }
}
