import { Controller } from "@hotwired/stimulus"
import { getCachedAccountData } from "@models/account-data"

export default class extends Controller<HTMLElement> {
  connect(): void {
    const cachedAccountData = getCachedAccountData()

    if (cachedAccountData?.IsAuthenticated) {
      this.element.innerText = cachedAccountData.FirstName
    }
  }
}
