import { Controller } from "@hotwired/stimulus"
import {
  checkSize,
  checkFormat,
} from "@utils/file_validation/file_validation.utils"

export default class extends Controller {
  readonly sizeErrorMessage: string = "Votre fichier est trop lourd."
  readonly formatErrorMessage: string = `Votre fichier n'est pas au bon format.`

  static targets: string[] = ["input", "error", "message", "title"]
  readonly inputTarget: HTMLInputElement
  readonly errorTarget: HTMLDivElement
  readonly messageTarget: HTMLDivElement
  readonly titleTarget: HTMLSpanElement

  static classes: string[] = ["error", "display", "hidden"]
  readonly errorClasses: string[]
  readonly displayClass: string
  readonly hiddenClass: string

  connect(): void {
    this.dispatch("connected")
  }

  check(): void {
    const file = this.inputTarget.files[0]
    this.reset()
    if (!checkFormat(file, this.getAcceptedFormats())) {
      this.showError(this.formatErrorMessage)
    } else if (!checkSize(file, this.getMaxSize())) {
      this.showError(this.sizeErrorMessage)
    } else {
      this.dispatch("valid", { target: this.inputTarget })
    }
  }

  private showError(message: string): void {
    this.errorTarget.classList.replace(this.hiddenClass, this.displayClass)
    this.titleTarget?.classList.add(...this.errorClasses)

    this.messageTarget.textContent = message
  }
  private reset(): void {
    this.errorTarget.classList.replace(this.displayClass, this.hiddenClass)
    this.titleTarget?.classList.remove(...this.errorClasses)
  }

  private getAcceptedFormats(): string[] {
    return this.inputTarget.attributes.getNamedItem("accept")?.value.split(",")
  }

  private getMaxSize(): number {
    return Number.parseInt(
      this.inputTarget.attributes.getNamedItem("max-size")?.value,
    )
  }
}
