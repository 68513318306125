import {
  getItemFromSessionStorage,
  removeItemFromSessionStorage,
  setItemInSessionStorage,
} from "@utils/session_storage/session_storage.utils"

export interface CachedAccountData {
  IsAuthenticated: boolean
  FirstName: string
}

export interface RoStatus {
  id: number
  status: number
}
export interface AccountDataInterface {
  AccountInfo: any
  Bookmarks: string[]
  CandidatPays: string
  CvCvtk: null | CV
  CvRo: CV[]
  DepotCvOneClick: boolean
  DisplayName: string
  Email: string
  Id: number
  FirstName: string
  HasNewsletterSubscription: boolean
  HasPushSubscription: boolean
  HasServiceAdaptation: boolean
  IpHorsEurope: boolean
  IsAuthenticated: boolean
  IsSerpRoOk: boolean
  LastName: string
  NbOffers: number
  Ro: any
  RoUrlIsActive: boolean
  RoUrlWithFormIsActive: boolean
  RoStatus: RoStatus[]
}
interface CV {
  Id: string
  Hash: string
  Name: string
  LastUsed: boolean
  DateUpdated: string
}
export interface DataEngineeringTrackers {
  crit: []
  ta: number
  tc: number
  iid: number
  aid: number
}
export interface RequestBody {
  offerIds: Array<string>
  track: DataEngineeringTrackers
  loadEmail?: boolean
  LoadListCv?: boolean
  LoadSmartApplyCandidateInfo?: boolean
  LoadHasPushSubscription?: boolean
  LoadDepotCvOneClick?: boolean
  CompanyId?: string
  OfferTypeResponse?: number
}

export const CLASSES_WHEN_USER_CONNECTED = ["isConnected", "tw-isConnected"]
export const ACCOUNT_DATA_READY = "accountDataReady"
const HW_ACCOUNT_DATA_CACHE_KEY = "HW_accountDataCache"

export async function getAccountData(): Promise<AccountDataInterface> {
  if (window._privateAccountData) {
    return Promise.resolve(window._privateAccountData)
  }

  return new Promise<AccountDataInterface>((resolve) => {
    document.addEventListener(
      ACCOUNT_DATA_READY,
      () => {
        resolve(window._privateAccountData)
      },
      { once: true },
    )
  })
}

/**
 * Update accountData cache
 */
export function updateCache(data: AccountDataInterface): void {
  if (data.IsAuthenticated) {
    const cachedAccountData: CachedAccountData = {
      IsAuthenticated: true,
      FirstName: data.FirstName,
    }
    setItemInSessionStorage(
      HW_ACCOUNT_DATA_CACHE_KEY,
      JSON.stringify(cachedAccountData),
    )
  } else {
    removeItemFromSessionStorage(HW_ACCOUNT_DATA_CACHE_KEY)
  }
}

export function updateUIFromCache(): void {
  const cachedAccountData = getCachedAccountData()
  if (cachedAccountData?.IsAuthenticated) {
    document.body.classList.add(...CLASSES_WHEN_USER_CONNECTED)
  } else {
    document.body.classList.remove(...CLASSES_WHEN_USER_CONNECTED)
  }
}

export function getCachedAccountData(): CachedAccountData {
  return JSON.parse(getItemFromSessionStorage(HW_ACCOUNT_DATA_CACHE_KEY))
}

export function getDataEngineeringTrackers(): DataEngineeringTrackers {
  const tpCt: any = extractMetaContent("rjtrak")
  return {
    crit: tpCt !== null ? JSON.parse(tpCt) : [],
    ta: parseInt(extractMetaContent("rjtrackaction"), 10),
    tc: parseInt(extractMetaContent("rjtrackcontent"), 10),
    iid: parseInt(extractMetaContent("rjtrackcontentid"), 10),
    aid: parseInt(extractMetaContent("rjtrackcontentappid")),
  }
}

function extractMetaContent(name: string): string {
  const meta = document.querySelector<HTMLMetaElement>(`meta[name=${name}]`)
  return meta ? meta.content : null
}
