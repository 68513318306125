import { hasCookie } from "@utils/cookie_storage/cookie_storage.utils"
import { getItemFromSessionStorage } from "@utils/session_storage/session_storage.utils"
import { platformForInstallableApp } from "@utils/user_agent/user_agent.utils"

export const AT_LEAST_SECOND_PAGE_KEY = "at_least_second_page"
export const HAS_CLOSED_BANNER_KEY = "has_closed_banner"

export function isSmartBannerDisplayable(): boolean {
  return platformForInstallableApp() && !hasCookie(HAS_CLOSED_BANNER_KEY) && !hasConversionBox()
}

export function isAtLeastSecondPage(): boolean {
  return !!getItemFromSessionStorage(AT_LEAST_SECOND_PAGE_KEY)
}

function hasConversionBox(): boolean {
  return !!document.querySelector("#conversionBox")
}
