import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    named: String,
  }
  readonly namedValue: string

  connect(): void {
    if (!(this.namedValue in this.element)) {
      throw new Error(`${this.element} has not attribut ${this.namedValue}`)
    } else {
      this.element.removeAttribute(this.namedValue)
    }
  }
}
