import { Controller } from "@hotwired/stimulus"
import { getMessageFromErrorAndInput } from "@utils/input_error/input_error_messages.utils"

export default class extends Controller {
  static values = {
    force: {
      type: Boolean,
      default: false,
    },
  }
  private forceValue: string

  static targets = ["input", "error", "errorMessage", "label", "container"]
  readonly inputTargets: HTMLInputElement[]
  readonly errorTarget: HTMLSpanElement
  readonly errorMessageTarget: HTMLSpanElement
  readonly labelTarget: HTMLLabelElement
  readonly containerTarget: HTMLDivElement

  static classes = ["labelError", "showError", "hideError", "containerError"]
  readonly labelErrorClass: string
  readonly showErrorClass: string
  readonly hideErrorClass: string
  readonly containerErrorClass: string
  readonly hasContainerTarget: boolean
  readonly hasLabelTarget: boolean

  checkValidity(): void {
    // search for an invalid input in targets within the controller to display error or not
    const invalid = this.inputTargets.find((input) => !input.checkValidity())
    
    // if input is not displayed, we do not check it
    // Resume (cv) is an exception because he is hidden
    invalid && (invalid.offsetHeight > 0 || this.forceValue)
      ? this.showError(invalid)
      : this.hideError()
    
  }

  private showError(input: HTMLInputElement): void {
    this.errorTarget.classList.replace(this.hideErrorClass, this.showErrorClass)
    // label is optional
    if (this.hasLabelTarget) {
      this.labelTarget.classList.add(this.labelErrorClass)
    }
    // container is optional
    if (this.hasContainerTarget) {
      this.containerTarget.classList.add(this.containerErrorClass)
    }

    this.errorMessageTarget.textContent = getMessageFromErrorAndInput(
      input.validity,
      input.name,
    )
  }

  public hideError(): void {
    this.errorTarget.classList.replace(this.showErrorClass, this.hideErrorClass)

    if (this.hasLabelTarget) {
      this.labelTarget.classList.remove(this.labelErrorClass)
    }

    if (this.hasContainerTarget) {
      this.containerTarget.classList.remove(this.containerErrorClass)
    }

    this.errorMessageTarget.textContent = ""
  }
}
