import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["source"]
  readonly sourceTarget: HTMLElement

  /**
   *
   */
  async copyContent() {
    return await navigator.clipboard.writeText(this.sourceTarget.textContent)
  }

  /**
   *
   */
  async copyHtml() {
    const clone = this.sourceTarget.cloneNode() as Element
    clone.removeAttribute("data-clipboard-target")
    clone.removeAttribute("data-action")

    return await navigator.clipboard.writeText(clone.outerHTML)
  }

  /**
   *
   */
  async copySvg() {
    const clone = this.sourceTarget.cloneNode() as Element
    clone.removeAttribute("viewBox")
    clone.removeAttribute("fill")
    clone.removeAttribute("xmlns")
    clone.removeAttribute("data-clipboard-target")

    const url: string = clone.getAttribute("data-cross-origin-svg-url-value")
    const dirAndIconName: string = url.split("/img/svg/")[1].split(".svg")[0]

    clone.setAttribute(
      "data-cross-origin-svg-url-value",
      `@_urlService.ResourcesUrlRelative("/img/svg/${dirAndIconName}.svg", ConfigRessourceType.Unified)`,
    )

    // setAttribute encodes value even if character is escaped, so we must replace it
    const result = clone.outerHTML.toString().replaceAll("&quot;", '"')

    return await navigator.clipboard.writeText(result)
  }
}
