const defaultSize = 2097152
const defaultFormats = [
  ".rtf",
  " .dot",
  ".doc",
  ".docx",
  ".pdf",
  ".odt",
  ".wpd",
  ".ods",
  ".gif",
  ".png",
  ".jpg",
  ".jpeg",
  ".bmp",
]

/**
 * Size in MegaBytes
 * @param file
 * @param maxSize
 */
export function checkSize(file: File, maxSize: number): boolean {
  maxSize = maxSize ?? defaultSize
  if (isNaN(maxSize)) maxSize = defaultSize

  return file.size <= maxSize
}

/**
 *
 * @param file
 * @param acceptedFormats
 */
export function checkFormat(file: File, acceptedFormats: string[]): boolean {
  acceptedFormats = acceptedFormats ?? defaultFormats
  const fileFormat: string = file.name.split(".").pop()

  return acceptedFormats.includes(`.${fileFormat.toLowerCase()}`)
}
