import { Controller } from "@hotwired/stimulus"
import { formatToEuroSalary } from "@utils/salary_formatter/salary_formatter.utils"
import { annualToMonthly, monthlyToHourly } from "@utils/salary_calculator/salary_calculator.utils"
import FORMAT_OPTIONS from "@utils/salary_formatter/salary_formatter.constants"

export default class extends Controller {
  static targets = ["input", "annualOutput", "monthlyOutput", "hourlyOutput"]

  readonly inputTarget: HTMLInputElement
  readonly annualOutputTarget: HTMLOutputElement
  readonly monthlyOutputTarget: HTMLOutputElement
  readonly hourlyOutputTarget: HTMLOutputElement

  monthlyOutputTargetConnected() {
    this.#updateMonthlyOutput()
  }

  hourlyOutputTargetConnected() {
    this.#updateHourlyOutput()
  }

  updateOutputs(): void {
    this.annualOutputTarget.replaceChildren(formatToEuroSalary(this.inputTarget.value))

    this.#updateMonthlyOutput()
    this.#updateHourlyOutput()
  }

  #updateMonthlyOutput(): void {
    const result = annualToMonthly(parseInt(this.inputTarget.value))

    this.monthlyOutputTarget.replaceChildren(formatToEuroSalary(result))
  }

  #updateHourlyOutput(): void {
    const monthly = annualToMonthly(parseInt(this.inputTarget.value))
    const result = monthlyToHourly(monthly)

    this.hourlyOutputTarget.replaceChildren(formatToEuroSalary(result, FORMAT_OPTIONS.DIGITS.TWO))
  }
}
