import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    input: String,
  }
  readonly inputValue: string

  check(): void {
    ;(<HTMLInputElement>document.querySelector(this.inputValue)).checked = true
  }

  uncheck(): void {
    ;(<HTMLInputElement>document.querySelector(this.inputValue)).checked = false
  }
}
