import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["destination"]
  readonly destinationTarget: HTMLElement
  private hasDestinationTarget: boolean

  static classes = ["connected"]
  readonly connectedClasses: string[]

  connect(): void {
    this.element.classList.add(...this.connectedClasses)

    // We add event on click to hide the action on html because of SEO issue
    this.element.addEventListener("click", this.dispatchClick)
  }

  disconnect(): void {
    this.element.removeEventListener("click", this.dispatchClick)
  }

  private dispatchClick = <EventListener>((event: PointerEvent): void => {
    if (this.hasDestinationTarget && event.target !== this.destinationTarget) {
      this.destinationTarget.dispatchEvent(new MouseEvent("click"))
    }
  })
}
