import { Controller } from "@hotwired/stimulus"

export default class UpdateTurboSrcController extends Controller {
  static targets = ["turboFrame"]
  declare turboFrameTarget: HTMLElement
  declare hasTurboFrameTarget: boolean

  static values = {
    endpoint: String,
  }
  declare endpointValue: string

  update(): void {
    if (this.hasTurboFrameTarget) {
      this.turboFrameTarget.setAttribute("src", this.endpointValue)
    }
  }
}
