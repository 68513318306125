import Service from "./service.abstract";

export default class OfferFormService extends Service {
  constructor() {
    super();
  }

  setDisplayDefaultError(status: boolean) {
    this.displayDefaultError = status;
  }

  setUrl(url: string):void {
    this.url = url;
  }
}
