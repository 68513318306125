import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    eventName: {
      type: String,
      default: "connected",
    },
  }
  readonly eventNameValue: string

  connect(): void {
    this.dispatch(this.eventNameValue);
  }
}
