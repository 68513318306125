import { Controller } from "@hotwired/stimulus"

/**
 * Switch the display between two div
 * Hide the secondary one on connecting the controller (SEO driven development)
 */
export default class SwitchDisplayController extends Controller {
  static targets = [
    "firstTabContent",
    "secondaryTabContent",
    "mainTab",
    "secondTab",
  ]
  readonly firstTabContentTarget: HTMLDivElement
  readonly secondaryTabContentTarget: HTMLDivElement
  readonly mainTabTarget: HTMLLabelElement
  readonly secondTabTarget: HTMLLabelElement

  static classes = ["hide", "show", "active"]
  readonly showClass: string
  readonly hideClass: string
  readonly activeClass: string

  connect(): void {
    this.secondaryTabContentTarget.classList.add(this.hideClass)
    this.setActiveTab()
  }

  switchTabContent(): void {
    this.firstTabContentTarget.classList.contains(this.showClass)
      ? this.firstTabContentTarget.classList.replace(
          this.showClass,
          this.hideClass,
        )
      : this.firstTabContentTarget.classList.replace(
          this.hideClass,
          this.showClass,
        )

    this.secondaryTabContentTarget.classList.contains(this.showClass)
      ? this.secondaryTabContentTarget.classList.replace(
          this.showClass,
          this.hideClass,
        )
      : this.secondaryTabContentTarget.classList.replace(
          this.hideClass,
          this.showClass,
        )

    this.setActiveTab()
  }

  /**
   * Switch from tab to tab based on index param send by an event
   * 
   * It's a base for multi tab (more than 2) switch.
   * Probably looping on targets array 
   * @param tabIndex from CustomEvent
   */
  displayTabContent(event: CustomEvent): void {
    const tabIndex : string = event.detail.param

    if (tabIndex === "0") {
      this.firstTabContentTarget.classList.remove(this.hideClass)
      this.firstTabContentTarget.classList.add(this.showClass)

      this.secondaryTabContentTarget.classList.remove(this.showClass)
      this.secondaryTabContentTarget.classList.add(this.hideClass)
    }

    if (tabIndex === "1") {
      this.secondaryTabContentTarget.classList.remove(this.hideClass)
      this.secondaryTabContentTarget.classList.add(this.showClass)

      this.firstTabContentTarget.classList.remove(this.showClass)
      this.firstTabContentTarget.classList.add(this.hideClass)
    }
    
    this.setActiveTab()
  }

  private setActiveTab() {
    if (this.firstTabContentTarget.classList.contains(this.hideClass)) {
      this.mainTabTarget.classList.remove(this.activeClass)
      this.secondTabTarget.classList.add(this.activeClass)
    } else {
      this.secondTabTarget.classList.remove(this.activeClass)
      this.mainTabTarget.classList.add(this.activeClass)
    }
  }
}
