/**
 * Duration is in seconds
 * @param duration
 */
const toStringTime = (duration: number) => {
  const minutes = Math.floor(duration / 60)
  const remaining = duration % 60

  const minutesFormatted = minutes.toString().padStart(2, "0")
  const formatted = remaining.toString().padStart(2, "0")

  return `${minutesFormatted}:${formatted}`
}

export { toStringTime }
