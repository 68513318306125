import { ActionEvent, Controller } from "@hotwired/stimulus"
import { TurboBeforeFrameRenderEvent } from "@hotwired/turbo"
import morphdom from "morphdom"
import { CrossOriginSVGIdentifier } from "@utils/hardcoded_stimulus_identifiers"

export default class extends Controller {
  static values = {
    name: String,
  }

  private nameValue: string
  private hasNameValue: boolean

  static readonly targets = ["updatedField", "staticField", "submitter"]
  readonly updatedFieldTarget: HTMLInputElement
  readonly hasUpdatedFieldTarget: boolean

  readonly staticFieldTargets: Array<HTMLInputElement>
  readonly hasStaticFieldTarget: boolean

  readonly submitterTarget: HTMLButtonElement
  readonly hasSubmitterTarget: boolean

  submit(
    event: ActionEvent & {
      target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLButtonElement
    },
  ): void {
    if (this.hasUpdatedFieldTarget) {
      this.updatedFieldTarget.value = event.target.name
    }

    event.target.form.requestSubmit()

    const prefix = this.hasNameValue ? this.nameValue + ":" : ""
    this.dispatch(prefix + "submitted")
  }

  morph(e: TurboBeforeFrameRenderEvent): void {
    const skippedFieldList = []

    if (this.hasStaticFieldTarget) {
      for (const staticElement of this.staticFieldTargets) {
        skippedFieldList.push(staticElement.name)
      }
    }

    if (this.hasUpdatedFieldTarget) {
      skippedFieldList.push(this.updatedFieldTarget.value)
    }

    e.detail.render = morph({ skippedFieldList })
  }

  formSubmit(event: Event & { target: HTMLFormElement }) {
    this.hasSubmitterTarget ? this.submitterTarget.form.requestSubmit(this.submitterTarget) : event.target.submit()

    this.dispatch("submitted")
  }
}

export function morph({ skippedFieldList = [] } = {}) {
  return (fromElement: Element, toElement: Element): void =>
    morphdom(fromElement, toElement, {
      childrenOnly: true,
      onBeforeElUpdated(fromEl: Element, toEl: Element) {
        // Check before turbo render if Element should be skipped ( in skippedFieldList ) and it's a select or an input
        if (
          skippedFieldList &&
          skippedFieldList.length > 0 &&
          (fromEl instanceof HTMLInputElement || fromEl instanceof HTMLSelectElement) &&
          skippedFieldList.includes(fromEl.name)
        ) {
          return false
        }

        // If Element is an SVG and the value is the same, don't re-render
        return !(
          fromEl instanceof SVGSVGElement &&
          toEl instanceof SVGSVGElement &&
          fromEl.getAttribute(`data-${CrossOriginSVGIdentifier}-url-value`) ===
            toEl.getAttribute(`data-${CrossOriginSVGIdentifier}-url-value`)
        )
      },
    })
}
