import { Controller } from "@hotwired/stimulus"
import { NotificationLevel, notify } from "@utils/notification"

export default class extends Controller {
  static values = {
    message: String,
    level: String,
  }

  private messageValue: string
  private levelValue: NotificationLevel

  public connect(): void {
    notify(this.levelValue, this.messageValue)
  }
}
