import { ActionEvent, Controller } from "@hotwired/stimulus"
import {
  ACCOUNT_DATA_READY,
  AccountDataInterface,
  getDataEngineeringTrackers,
  updateCache,
  updateUIFromCache,
} from "@models/account-data"
export default class extends Controller {
  static targets = ["offersInPage"]
  // FIXME: Il serait mieux que cette target soit une value
  // Je n'ai pas pu modifier src/Hw.Emploi.Web.HelloWork/Views/Shared/Layout/_Base.cshtml
  // pour faire ce changement donc j'ai utilisé une target
  readonly hasOffersInPageTarget: boolean

  static values = {
    url: String,
  }
  readonly urlValue: string

  async connect(): Promise<void> {
    updateUIFromCache()
    const data = await this.#fetchAccountData()
    updateCache(data)
    updateUIFromCache()
    window.dataLayer.push({ Pays: data.CandidatPays })
  }

  async refreshAfterNextTurboEvent(event: ActionEvent): Promise<void> {
    delete window._privateAccountData
    document.addEventListener(
      event.params.event,
      async () => {
        await this.#fetchAccountData()
        this.dispatch("account-data-refreshed")
      },
      { once: true },
    )
  }

  async #fetchAccountData(): Promise<AccountDataInterface> {
    const requestBody: any = {
      track: getDataEngineeringTrackers(),
    }

    if (this.hasOffersInPageTarget) {
      // FIXME: This relies on the backend always returning the full list of bookmarks
      requestBody.offerIds = ["notReadByBackend"]
    }

    const response = await fetch(this.urlValue, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })

    const data: AccountDataInterface = await response.json()
    window._privateAccountData = data
    document.dispatchEvent(new CustomEvent(ACCOUNT_DATA_READY))
    return data
  }
}
