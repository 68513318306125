import OPTIONS from "./salary_formatter.constants"

/**
 * Format to the euro standard string
 * @param salary
 * @param digits
 */
export function formatToEuroSalary(salary: number | string, digits: number = OPTIONS.DIGITS.NONE): string {
  // notation is an enum, cant be a string constant
  const salaryFormatter = new Intl.NumberFormat("fr-Fr", {
    style: "currency",
    currency: OPTIONS.CURRENCY.EURO,
    maximumFractionDigits: digits,
    notation: "standard",
  })

  return salaryFormatter.format(Number(salary))
}
