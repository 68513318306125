export default class FetchService {
  protected url: URL
  private errorHandler: any
  protected headers: Headers = new Headers()
  protected dataType: string

  constructor(url: string, dataType = "json") {
    this.url = new URL(url, document.location.origin)
    this.dataType = dataType
  }

  private setRandomParam() {
    this.url.searchParams.set("timestamp", new Date().valueOf() + "")
  }
  setErrorHandler(errorHandler: any): void {
    this.errorHandler = errorHandler
  }
  setHeaders(headers: Headers): void {
    this.headers = headers
  }
  protected handleError(error: unknown): Promise<unknown> {
    if (this.errorHandler) {
      this.errorHandler(error)
    }
    return Promise.reject(error)
  }

  async get(callback?: (data: unknown) => void): Promise<string | void> {
    this.setRandomParam()
    try {
      const response = await fetch(this.url.toString())
      if (!response.ok) {
        this.handleError(response)
      } else {
        let data: any
        if (this.dataType === "json") {
          data = await response.json()
        } else {
          data = await response.text()
        }
        if (callback) {
          callback(data)
        } else {
          return data
        }
      }
    } catch (e) {
      this.handleError(e)
    }
  }

  async post(
    payload?: any,
    callback?: (data: unknown) => void,
  ): Promise<unknown> {
    this.setRandomParam()
    try {
      const response = await fetch(this.url.toString(), {
        method: "POST",
        body: payload,
        headers: this.headers,
      })
      const data = await response.json()
      if (!response.ok) {
        this.handleError(response)
      } else {
        if (callback) {
          callback(data)
        } else {
          return data
        }
      }
    } catch (e) {
      this.handleError(e)
    }
  }
}
