import { Controller } from "@hotwired/stimulus"
import { createScriptElement } from "@utils/html/html.utils"
import { hasAcceptedMarketingCookies } from "@utils/cookie_storage/cookie_storage.utils"

export default class extends Controller {
  static targets = ["warning", "embed"]
  readonly warningTarget: HTMLElement
  readonly embedTarget: HTMLElement

  static classes = ["hiding", "showing"]
  readonly hidingClasses: Array<string>
  readonly showingClasses: Array<string>

  displayed: boolean

  connect(): void {
    this.displayed = false
    this.displayIfAllowed()

    // Listen to cookie consent box event
    document.addEventListener("marketingCookiesChanged", ((
      event: CustomEvent,
    ) => {
      if (event.detail.hasAccepted) {
        this.#display()
      }
    }) as EventListener)
  }

  allowMarketingCookies(): void {
    const acceptButton = <HTMLElement>(
      document.querySelector("#hw-cc-notice-accept-btn")
    )
    if (acceptButton) {
      acceptButton.click()
    }
  }

  displayIfAllowed(): void {
    if (hasAcceptedMarketingCookies()) {
      this.#display()
    }
  }

  #display(): void {
    if (!this.displayed) {
      const encodedHtml = this.embedTarget.textContent?.replace(/\+/g, "%20")
      if (encodedHtml) {
        createScriptElement(this.embedTarget, decodeURIComponent(encodedHtml))
      }

      this.warningTarget.classList.add(...this.hidingClasses)
      this.embedTarget.classList.add(...this.showingClasses)
      this.embedTarget.classList.remove(...this.hidingClasses)

      this.displayed = true
    }
  }
}
