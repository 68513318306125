import axios, { AxiosInstance, AxiosRequestConfig, CancelTokenSource } from "axios";
import PubSub from "pubsub-js";
import { ALERT } from "../components/shared/notification/alert-level";

// Too old, many question
// why random param ?
// why handle error and response without arrow function
// why axios.request ? axios.get, axios.post are more explicit
// why always create a class with specific url ?
// config is always the same, it could be a private const or something
// new service example by mbe --> controllers/services/candidate_service.ts

/**
 * @deprecated
 * Abstract Class for all services
 */
export default abstract class Service {
  private axios: AxiosInstance;
  protected url: string;
  protected params: URLSearchParams;
  protected errorTopic: string;
  protected errorNotification: any;
  private cancelToken: CancelTokenSource;
  protected displayDefaultError: boolean;
  protected requestedWithHeaders: boolean;
  private errorHandler: any;
  protected contentType: string;
  protected antiForgeryToken: string;
  protected textResponse: boolean;

  constructor() {
    this.axios = axios.create();
    this.axios.interceptors.response.use(this.handleSuccess, this.handleError.bind(this));
    this.params = new URLSearchParams();
    this.requestedWithHeaders = false;
    this.textResponse = false;

    this.displayDefaultError = true;
    this.errorTopic = "service.error";
    this.errorNotification = {
      action: ALERT.ERROR,
      messages: [
        "Aïe, une erreur est survenue, rechargez la page et réessayez.",
      ],
      sticky: false,
      title: "",
      vanish: false,
    };
  }

  setErrorHandler(errorHandler: any): void {
    this.errorHandler = errorHandler;  
  }

  private handleSuccess(response: any) {
    return response;
  }

  private handleError(error: any): Promise<never> {
    if (this.displayDefaultError) {
      PubSub.publish(this.errorTopic, this.errorNotification);
    }

    if (this.errorHandler) {
      this.errorHandler(error?.response?.data);
    }

    return Promise.reject(error);
  }

  private setRandomParam() {
    this.params.set("timestamp", new Date().valueOf() + "");
  }

  private setCancelToken() {
    this.cancelToken = axios.CancelToken.source();
  }

  setUrl(url: string): void {
    this.url = url;
  }

  get(callback: any) {
    this.setRandomParam();
    this.setCancelToken();
    return this.axios
      .get(this.url, {
        params: this.params,
        cancelToken: this.cancelToken.token,
        headers: this.requestedWithHeaders
          ? { "X-Requested-With": "XMLHttpRequest" }
          : {},
      })
      .then((response) => callback(response?.data))
      .catch(error => error);
  }

  delete(payload: any, callback: any) {
    this.setRandomParam();
    this.setCancelToken();
    const config: AxiosRequestConfig = {
      method: "DELETE",
      url: this.url,
      params: this.params,
      cancelToken: this.cancelToken.token,
    };

    if (payload) {
      config.data = payload;
    }

    return this.axios
      .request(config)
      .then((response) => callback(response?.data))
      .catch(error => error);
  }

  post(payload: any, callback?: any) {
    this.setRandomParam();
    this.setCancelToken();
    const config: AxiosRequestConfig = {
      method: "POST",
      url: this.url,
      params: this.params,
      cancelToken: this.cancelToken.token,
      responseType: this.textResponse ? "text" : "json"
    };

    const headers: any = this.requestedWithHeaders
      ? { "X-Requested-With": "XMLHttpRequest" }
      : { };
    if (this.contentType) {
      headers["content-type"] = this.contentType;
    }
    if (this.antiForgeryToken) {
      headers["hwantiforgerytoken"] = this.antiForgeryToken;
    }

    config.headers = headers;

    if (payload) {
      config.data = payload;
    }

    return this.axios
      .request(config)
      .then((response) => {
        callback(response?.data)
      })
      .catch(error => {
        return error
      });
  }
    
  put(payload: any, callback: any) {
    this.setRandomParam();
    this.setCancelToken();
    const config: AxiosRequestConfig = {
      method: "PUT",
      url: this.url,
      params: this.params,
      cancelToken: this.cancelToken.token,
      responseType: "json", // TODO: Set in parameter?
      headers: this.requestedWithHeaders
        ? { "X-Requested-With": "XMLHttpRequest" }
        : {},
    };

    if (payload) {
      config.data = payload;
    }

    return this.axios
      .request(config)
      .then((response) => callback(response?.data))
      .catch(error => error);
  }

  addParam(name: string, value: string): void {
    this.params.append(name, value);
  }

  clearParams(): void {
    this.params = new URLSearchParams();
  }

  // Cancel previous request if any
  cancelPrevious(): void {
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }
  }

  setAntiForgeryToken(token: string): void {
    this.antiForgeryToken = token;
  }
}