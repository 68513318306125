import { Controller } from "@hotwired/stimulus"
import { TemplateInstance } from "@github/template-parts"
import { Suggestion } from "../autocomplete_controller"

export default class extends Controller {
  static values = {
    maxItems: {
      type: Number,
      default: Infinity,
    },
  }
  readonly maxItemsValue: number

  static targets = [
    "field",
    "itemTemplate",
    "list",
    "item",
    "displayWhenEmpty",
    "highlightWhenEmpty",
    "input",
    "hiddenInput",
  ]
  readonly fieldTarget: HTMLInputElement
  readonly itemTemplateTarget: HTMLTemplateElement
  readonly itemTargets: HTMLLIElement[]
  readonly listTarget: HTMLUListElement
  readonly displayWhenEmptyTargets: HTMLElement[]
  readonly highlightWhenEmptyTargets: HTMLElement[]
  readonly hiddenInputTarget: HTMLInputElement
  readonly hasHiddenInputTarget: boolean

  static classes = ["errorDisplay", "errorHighlight"]
  readonly errorDisplayClasses: string[]
  readonly errorHighlightClasses: string[]

  addItemIfNeeded(event: CustomEvent<Suggestion>): void {
    // Template element must set item id in the View : data-autocomplete-multiple-id="{{id}}"
    const itemAlreadyAdded = this.listTarget.querySelector(
      `[data-autocomplete-multiple-id="${event.detail.id}"]`,
    )
    if (itemAlreadyAdded || this.#hasReachedLimit()) {
      return
    }

    if (this.hasHiddenInputTarget) {
      this.#pushItemInputHidden(event.detail.id)
    }

    const templateInstance = new TemplateInstance(
      this.itemTemplateTarget,
      event.detail,
    )
    this.listTarget.append(templateInstance)
  }

  removeItemInputHidden(event: PointerEvent) {
    const el = <HTMLElement>event.currentTarget
    const id = el.closest("li").dataset.autocompleteMultipleId

    const hiddenInputValues = this.#getHiddenInputValues()
    const indexToDelete = hiddenInputValues.indexOf(id)
    hiddenInputValues.splice(indexToDelete, 1)
    this.hiddenInputTarget.value = hiddenInputValues.join(",")
  }

  #pushItemInputHidden(value: string): void {
    const hiddenInputValues = this.#getHiddenInputValues()
    hiddenInputValues.push(value)
    this.hiddenInputTarget.value = hiddenInputValues.join(",")
  }

  #getHiddenInputValues(): string[] {
    return this.hiddenInputTarget.value
      ? this.hiddenInputTarget.value.split(",")
      : []
  }

  checkValidity(): boolean {
    this.#showRequiredErrorIfEmpty()
    return !!this.itemTargets.length
  }

  itemTargetConnected(): void {
    this.#toggleDisabledIfLimit()

    this.hideRequiredError()
  }

  itemTargetDisconnected(): void {
    this.#toggleDisabledIfLimit()

    this.#showRequiredErrorIfEmpty()
  }

  #toggleDisabledIfLimit(): void {
    this.fieldTarget.disabled = this.#hasReachedLimit()
  }

  #hasReachedLimit(): boolean {
    return this.itemTargets.length >= this.maxItemsValue
  }

  #showRequiredErrorIfEmpty(): void {
    // FIXME: Rajouter une value "required" pour déterminer si on fait un early return
    // A supprimer, à la refonte de la page de création d'alerte

    if (this.itemTargets.length) {
      return
    }

    for (const displayWhenEmptyTarget of this.displayWhenEmptyTargets) {
      displayWhenEmptyTarget.classList.remove(...this.errorDisplayClasses)
    }
    for (const highlightWhenEmptyTarget of this.highlightWhenEmptyTargets) {
      highlightWhenEmptyTarget.classList.add(...this.errorHighlightClasses)
    }
  }

  hideRequiredError(): void {
    for (const displayWhenEmptyTarget of this.displayWhenEmptyTargets) {
      displayWhenEmptyTarget.classList.add(...this.errorDisplayClasses)
    }
    for (const highlightWhenEmptyTarget of this.highlightWhenEmptyTargets) {
      highlightWhenEmptyTarget.classList.remove(...this.errorHighlightClasses)
    }
  }
}
