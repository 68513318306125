import { Controller } from "@hotwired/stimulus"
import { AccountDataInterface, getAccountData } from "@models/account-data"

export default class extends Controller {
  static targets = ["info", "error"]
  readonly infoTarget: HTMLElement
  readonly errorTarget: HTMLElement

  static classes = ["toggled"]
  readonly toggledClass: string

  static values = {
    id: Number,
  }
  readonly idValue: number

  async connect(): Promise<void> {
    const data = await getAccountData()
    if (data.IsAuthenticated) {
      this.checkRoStatus(data)
    }
  }

  checkRoStatus(data: AccountDataInterface): void {
    const statusFound = data.RoStatus.find(
      (status) => status.id == this.idValue,
    )
    if (statusFound) {
      if (statusFound.status == 21) {
        // Marvin Error
        this.errorTarget.classList.toggle(this.toggledClass)
      } else {
        this.infoTarget.classList.toggle(this.toggledClass)
      }
    }
  }
}
