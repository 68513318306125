import { Controller } from "@hotwired/stimulus"
import { Suggestion } from "../autocomplete_controller"
export default class extends Controller {
  static targets = ["field", "hiddenInput"]
  readonly fieldTarget: HTMLInputElement
  readonly hiddenInputTarget: HTMLInputElement

  fillSuggestion(event: CustomEvent<Suggestion>): void {
    this.fieldTarget.value = event.detail.label
    this.hiddenInputTarget.value = event.detail.id
  }

  clearSuggestion(): void {
    this.hiddenInputTarget.value = ""
  }
}
