/**
 * Toggle a property value on a HTMLInputElement
 * To make it work with other properties than "checked", just add it:
 * readonly propertyValue: "checked" | "disabled";
 */

import { Controller } from "@hotwired/stimulus"

export default class TogglePropertyController extends Controller {
  static readonly targets = ["toToggle", "toToggleOnConnect"]
  readonly toToggleTarget: HTMLInputElement
  readonly toToggleOnConnectTarget: HTMLInputElement

  static readonly values = {
    named: String,
  }
  readonly namedValue: "checked" | "disabled"| "readOnly"

  toToggleOnConnectTargetConnected(): void {
    this.#toggleProperty(this.toToggleOnConnectTarget)
  }

  toggle(): void {
    this.#toggleProperty(this.toToggleTarget)
  }

  #toggleProperty(target: HTMLInputElement): void {
    target[this.namedValue] = !target[this.namedValue]
    this.dispatch("toggled")
  }
}
