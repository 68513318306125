import { Controller } from "@hotwired/stimulus"
import FetchService from "@services/fetch_service"

interface RequestParams {
  params: {
    url: string
    payload: string
  }
}

export default class extends Controller {
  submit({ params: { url, payload } }: RequestParams) {
    const customHeaders = new Headers()
    customHeaders.append("Content-Type", "application/json")

    const service = new FetchService(url)
    service.setHeaders(customHeaders)
    service.post(JSON.stringify(payload), this.handleResponse.bind(this))
    service.setErrorHandler((error: any) => {
      this.dispatch("failure", { detail: error })
    })
  }
  handleResponse(data: any): void {
    this.dispatch("success", { detail: data })
  }
}
