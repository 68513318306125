export default {
  VALUE_MISSING: {
    FIRSTNAME: "Il nous manque votre prénom.",
    NAME: "Oups, quel est votre nom ?",
    EMAIL: `N'oubliez pas votre email.`,
    RESUME: "Pas si vite, et votre CV ?",
    JOB: `N'oubliez pas de choisir votre métier dans la liste proposée.`,
    JOB_MULTIPLE: `N'oubliez pas de choisir au moins un métier dans la liste proposée.`,
    LOCALITY: `N'oubliez pas de choisir votre localité dans la liste proposée.`,
    LOCALITY_MULTIPLE: `N'oubliez pas de choisir au moins une localité dans la liste proposée.`,
    CONTRACTS: "Il nous faudrait au moins un type de contrat.",
    JOB_AND_DOMAIN: "Vous devez sélectionner un métier ou un domaine dans la liste proposée.",
    OTP: `N'oubliez pas le code.`,
    RANDOMS: [
      "Il nous manque cette information.",
      "Nous avons besoin de cette information.",
      "Il nous faudrait cette information.",
      `N'oubliez pas cette information.`,
    ],
  },
  TOO_SHORT: {
    DEFAULT: `L'information est incomplète.`,
    OTP: "Le code est incomplet.",
  },
  PATTERN_MISSMATCH: {
    EMAIL: `Votre email n'est pas au bon format.`,
    ZIP_CODE: "Votre code postal n'est pas valide.",
    PHONE: "Votre numéro de téléphone n'est pas valide.",
    OTP: `Le code ne peut contenir que des chiffres.`,
    DEFAULT: "Certains caractères ne sont pas acceptés.",
  },

  DEFAULT: "Une erreur est survenue.",
}
