import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    defaultUrl: String,
  }
  readonly defaultUrlValue: string

  redirect(): void {
    const reffererURL = document.referrer ? new URL(document.referrer) : null

    // if there is a document referrer that belongs to our domain and not excluded page we send user there, otherwise we send him to default back url
    window.location.href =
      !reffererURL || this.isSameHref(reffererURL.href) || !this.isSameOrigin(reffererURL.origin) || this.isExcluded()
        ? this.defaultUrlValue
        : document.referrer
  }

  /**
   * Exclude some url which we don't want to go back on
   * Excluded: onboarding page (/bienvenue)
   * Excluded: landing page page (/lp)
   * @private
   */
  private isExcluded(): boolean {
    return document.referrer.includes("bienvenue") || document.referrer.includes("/lp/")
  }

  private isSameOrigin(origin: string): boolean {
    return origin === window.location.origin
  }

  private isSameHref(href: string) {
    return href === window.location.href
  }
}
