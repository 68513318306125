import { Controller } from "@hotwired/stimulus"
import FetchService from "@services/fetch_service"

export default class extends Controller {
  static values = {
    url: String,
    datatype: String,
  }
  readonly urlValue: string
  readonly datatypeValue: string
  readonly hasDatatypeValue: boolean
  protected dataType: string | undefined

  connect(): void {
    if (this.hasDatatypeValue) {
      this.dataType = this.datatypeValue
    }
  }

  load(): void {
    const service = new FetchService(this.urlValue, this.dataType)
    service.get(this.handleResponse.bind(this))
    service.setErrorHandler((error: any) => {
      this.dispatch("failure", { detail: error })
    })
  }
  handleResponse(data: any): void {
    this.dispatch("success", { detail: data })
  }
}
