import { Controller } from "@hotwired/stimulus"

interface Contracts {
  label: string
  selected: boolean
}

export default class extends Controller {
  static classes = ["hide"]
  readonly hideClass: string

  checkIfDisplay(event: CustomEvent): void {
    if (event.detail) {
      if (
        event.detail.jobs.length === 0 ||
        event.detail.locations.length === 0 ||
        !this.checkContract(event.detail.contractTypes)
      ) {
        this.element.classList.remove(this.hideClass)
      }
    }
  }

  close(): void {
    this.element.classList.add(this.hideClass)
  }

  private checkContract(contracts: Contracts[]): boolean {
    let valid = false
    valid = contracts.some((contract) => contract.selected)
    return valid
  }
}
