import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container"]
  readonly containerTarget: HTMLElement

  build(event: CustomEvent): void {
    this.containerTarget.innerHTML = event.detail
  }
}
