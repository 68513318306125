import { Application, ControllerConstructor } from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"

declare global {
  interface Window {
    Stimulus: Application
  }
}

window.Stimulus = Application.start()
const context = require.context("../controllers", true, /_controller\.ts$/)

loadControllers(getControllersFromContext(context))

window.Stimulus.debug = process.env.NODE_ENV === "development"

// FIXME: Wait until "session" is added to TurboGlobal or do a PR
// Cf. https://github.com/DefinitelyTyped/DefinitelyTyped/tree/master/types/hotwired__turbo
//;(Turbo as unknown as Turbo.TurboGlobal).session.drive = false
// eslint-disable-next-line @typescript-eslint/no-explicit-any
;(Turbo as any).session.drive = false

function getControllersFromContext(context: __WebpackModuleApi.RequireContext) {
  return context.keys().map((controller) => {
    const name = controller.split("/").pop().replace(".ts", "").split("_").join("-").replace("-controller", "")

    return {
      name,
      constructor: context(controller).default,
    } as ControllerContext
  })
}

function loadControllers(controllers: ControllerContext[]) {
  controllers.forEach((controller: ControllerContext) =>
    window.Stimulus.register(controller.name, controller.constructor),
  )
}

interface ControllerContext {
  name: string
  constructor: ControllerConstructor
}
