import { Controller } from "@hotwired/stimulus"
/**
 * Dispatch an event if the current url hash is equal to the param anchorValue
 * @param anchorValue string with anchor value 
 */
export default class AnchorController extends Controller {
    static values = {
        anchor : String,
        param: String
    }
    declare anchorValue: string
    declare paramValue: string

    connect(): void {
        const matching = this.anchorValue === window.location.hash
        if(matching) this.dispatch("tabMatchingWithAnchor", {detail : { param : this.paramValue}})
    }
}