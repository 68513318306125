import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["inputRange", "outputRange"]
  readonly inputRangeTarget: HTMLInputElement
  readonly outputRangeTarget: HTMLOutputElement
  readonly hasOutputRangeTarget: boolean

  static values = {
    reverse: {
      type: Boolean,
      default: false,
    },
  }
  readonly reverseValue: boolean

  static classes = ["reverse"]
  readonly reverseClasses: string

  connect(): void {
    this.trackStyle()
  }

  track(): void {
    this.trackStyle()
  }

  reset(customEvent: any): void {
    this.inputRangeTarget.value = customEvent.params.value ?? ""
    this.inputRangeTarget.disabled = customEvent.params.disabled ?? false

    this.trackStyle()
  }

  private trackStyle() {
    const min = parseFloat(this.inputRangeTarget.min || "0")
    const max = parseFloat(this.inputRangeTarget.max || "100")
    const val = parseFloat(this.inputRangeTarget.value || "50")
    let direction = val - min

    if (this.reverseValue) {
      direction = max - val
      this.inputRangeTarget.classList.add(...this.reverseClasses)
    }

    this.inputRangeTarget.style.backgroundSize = (direction * 100) / (max - min) + "% 100%"

    if (this.hasOutputRangeTarget) {
      this.outputRangeTarget.value = val.toString()
    }
  }
}
