import { Controller } from "@hotwired/stimulus"
import { copyAttributesAndChildren, createElementFromHTML } from "@utils/html/html.utils"

export default class extends Controller {
  static values = {
    url: String,
  }
  urlValue: string

  async urlValueChanged(): Promise<void> {
    // To keep the attributes we set in the page html (especially tailwind classes),
    // we copy the parts we want instead of replacing the element

    const response = await fetch(this.urlValue)
    const svgOuterHtml = await response.text()

    const svgToInject = createElementFromHTML(svgOuterHtml) as SVGSVGElement
    copyAttributesAndChildren(svgToInject, { into: this.element })
  }
}
