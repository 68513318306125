export function platformForInstallableApp(
  userAgent = navigator.userAgent,
): "ios" | "android" | undefined {
  if (/iPhone|iPod|iPad/i.test(userAgent)) {
    return "ios"
  }
  if (/Android/i.test(userAgent)) {
    return "android"
  }
}
