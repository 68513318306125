import { Controller } from "@hotwired/stimulus"
import { getAccountData } from "@models/account-data"

export default class extends Controller {
  async identify(): Promise<void> {
    const data = await getAccountData()
    if (data.Email && data.Id && window.tracker?.identify)
      window.tracker.identify(data.Email, data.Id)
  }
}
