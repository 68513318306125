import { Controller } from "@hotwired/stimulus"
import OfferFormService from "@services/offer-form-service"
// import CandidateService from './services/candidate_service'

// TODO: use better service when everyone agreed

interface SaveCandidateResponse {
  accountId: number
  accountState: string
  errorMessage: string
  trackings: string
}

export default class extends Controller {
  static targets = ["form", "nextStep", "firstStep"]
  static classes = ["show", "hide"]

  readonly formTarget: HTMLFormElement
  readonly firstStepTarget: HTMLDivElement
  readonly nextStepTarget: HTMLDivElement
  readonly showClass: string
  readonly hideClass: string

  // private candidateService: CandidateService
  private offerService: OfferFormService

  connect(): void {
    this.offerService = new OfferFormService()
    // this.candidateService = new CandidateService()
  }

  onContinue(): void {
    if (this.firstStepIsValid()) {
      this.createCandidateAccount()
    }
  }

  private showNextStep() {
    this.nextStepTarget.classList.replace(this.hideClass, this.showClass)
    this.firstStepTarget.remove()
  }

  private firstStepIsValid(): boolean {
    return (
      this.getElement("Firstname").checkValidity() &&
      this.getElement("Name").checkValidity() &&
      this.getElement("Email").checkValidity() &&
      this.getElement("Resume").checkValidity()
    )
  }

  private createCandidateAccount() {
    this.showNextStep()

    this.offerService.setUrl("/offres/savecandidateaccount")
    this.offerService.setDisplayDefaultError(false)

    this.offerService.post(
      {
        firstname: this.getElement("Firstname").value,
        name: this.getElement("Name").value,
        email: this.getElement("Email").value,
        id: parseInt(this.getElement("Offer.Id").value),
      },
      (response: SaveCandidateResponse) => {
        if (response.trackings) {
          window.dataLayer.push(JSON.parse(response.trackings))
        }
      },
    )
  }

  private getElement(name: string): HTMLInputElement {
    const firstStepElements = Array.from(this.formTarget.elements).map(
      (el) => el as HTMLInputElement,
    )
    return firstStepElements.find((el) => el.name === name)
  }
}
