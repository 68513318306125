import { Controller } from "@hotwired/stimulus"

const ACTION_TYPE_INTERVAL = "interval"
const ACTION_TYPE_TIMEOUT = "timeout"

export default class extends Controller {
  static values = {
    timeout: Number,
    actionType: {
      type: String,
      default: "timeout",
    },
  }
  readonly timeoutValue: number
  readonly actionTypeValue: "interval" | "timeout"

  private timer: number
  private isRunning: boolean
  private lastTimestamp: number
  private frameRequest: number

  start() {
    this.timer = 0
    this.isRunning = true
    this.lastTimestamp = performance.now()
    this._tick()
  }

  stop() {
    this.isRunning = false
    this.timer = 0
    cancelAnimationFrame(this.frameRequest)
  }

  pause() {
    this.isRunning = !this.isRunning
    if (this.isRunning) {
      this.lastTimestamp = performance.now()
      this._tick()
    } else {
      cancelAnimationFrame(this.frameRequest)
    }
  }

  disconnect() {
    cancelAnimationFrame(this.frameRequest)
  }

  private _tick() {
    if (!this.isRunning) return

    const now = performance.now()
    const elapsed = now - this.lastTimestamp
    this.timer += elapsed
    this.lastTimestamp = now

    this._checkIntervalEvent()
    this.frameRequest = requestAnimationFrame(() => this._tick())
  }

  private _checkIntervalEvent() {
    if (Math.floor(this.timer) > this.timeoutValue) {
      this.stop()
      if (this.actionTypeValue === ACTION_TYPE_INTERVAL) {
        this.start()
        this.dispatch(ACTION_TYPE_INTERVAL)
      } else {
        this.dispatch(ACTION_TYPE_TIMEOUT)
      }
    }
  }
}
