import { Controller } from "@hotwired/stimulus"
import { useClickOutside, useMatchMedia } from "stimulus-use"
import breakpoints from "@utils/breakpoints"

export default class extends Controller<HTMLDetailsElement> {
  enableClickOutside: () => void
  disableClickOutside: () => void

  connect(): void {
    const [observeClickOutside, unobserveClickOutside] = useClickOutside(this)
    this.enableClickOutside = observeClickOutside
    this.disableClickOutside = unobserveClickOutside
    useMatchMedia(this, {
      mediaQueries: breakpoints,
    })
  }

  expand(): void {
    this.element.open = true
  }
  collapse(): void {
    this.element.open = false
  }
}
