import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    category: Number,
  }
  readonly categoryValue: number

  checkIfShouldBeRemoved(): void {
    // Remove intersect observer for alert on scroll up if Category DO < 3 and not coming from gg4job
    if (this.categoryValue >= 3) {
      return
    }

    const queryString = window.location.search
    if (!queryString) {
      this.element.remove()
    }
    else {
      const urlParams = new URLSearchParams(queryString)
      const gg4job = urlParams.get("utm_campaign")
      if (gg4job !== "google_jobs_apply") {
        this.element.remove()
      }
    }
  }
}
