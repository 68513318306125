export function getItemFromSessionStorage(key: string): string {
  try {
    return sessionStorage.getItem(key)
  } catch (e) {
    return null
  }
}

export function setItemInSessionStorage(key: string, value: string): void {
  try {
    sessionStorage.setItem(key, value)
  } catch (e) {}
}

export function removeItemFromSessionStorage(key: string): void {
  try {
    sessionStorage.removeItem(key)
  } catch (e) {}
}

export function clearSessionStorage(): void {
  try {
    sessionStorage.clear()
  } catch (e) {}
}
