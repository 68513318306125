import type CrossOriginSvgController from "../../images/svg/cross_origin_svg_controller"

import { Controller } from "@hotwired/stimulus"
import { getAccountData } from "@models/account-data"
import { CrossOriginSVGIdentifier } from "@utils/hardcoded_stimulus_identifiers"

export default class extends Controller<HTMLElement> {
  static targets = ["icon", "button"]
  readonly iconTarget: SVGSVGElement
  readonly buttonTarget: HTMLButtonElement

  static values = {
    offerId: String,
    bookmarkedIcon: String,
    unbookmarkedIcon: String,
  }
  readonly offerIdValue: string
  readonly bookmarkedIconValue: string
  readonly unbookmarkedIconValue: string

  async connect(): Promise<void> {
    await this.highlight()
  }

  async highlight(): Promise<void> {
    const data = await getAccountData()
    if (data.IsAuthenticated) {
      const isBookmarked = data.Bookmarks.includes(this.offerIdValue)
      const svgUrl = isBookmarked ? this.bookmarkedIconValue : this.unbookmarkedIconValue

      this.buttonTarget.setAttribute("data-analytics-values-param", this.updatedAnalytics(isBookmarked))
      const crossOriginSVGController = this.application.getControllerForElementAndIdentifier(
        this.iconTarget,
        CrossOriginSVGIdentifier,
      ) as CrossOriginSvgController

      crossOriginSVGController.urlValue = svgUrl
    }
  }

  private updatedAnalytics(isBookmarked: boolean): string {
    const analytics = JSON.parse(this.buttonTarget.getAttribute("data-analytics-values-param"))
    analytics.EventName = isBookmarked ? "bookmark_delete" : "bookmark_add"
    return JSON.stringify(analytics)
  }
}
