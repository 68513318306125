export const WEEK = 7

export function endOfDayInNDays(days: number): Date {
  const res = new Date()
  res.setDate(res.getDate() + days)

  res.setHours(23)
  res.setMinutes(59)
  res.setSeconds(59)
  res.setMilliseconds(999)
  return res
}

export function hasReachedDate(
  date: string | number | Date,
  nbDay: number,
): boolean {
  const dateToReach = new Date(date)
  dateToReach.setDate(dateToReach.getDate() + nbDay)
  return new Date().getTime() > dateToReach.getTime()
}
