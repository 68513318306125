import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    all: Boolean,
  }

  private hasAllValue: boolean
  readonly allValue: string

  clear(): void {
    // Uncheck visible and checked checkbox inputs
    this.element
      .querySelectorAll("input[type=checkbox]:not([hidden]):checked")
      .forEach((checkbox: HTMLInputElement) => (checkbox.checked = false))

    // Check first radio of each visible radio input groups
    const alreadyChecked: string[] = []
    this.element.querySelectorAll("input[type=radio]:not([hidden])").forEach((radio: HTMLInputElement) => {
      if (!alreadyChecked.includes(radio.name)) {
        radio.checked = true
        alreadyChecked.push(radio.name)
      }
    })

    if (this.hasAllValue && this.allValue) {
      this.dispatch("clear")
    }
  }
}
