import { Controller } from "@hotwired/stimulus"

interface ScrollBehaviorParamsInterface {
  params : {
    behavior : ScrollBehavior
  }
}
export default class ScrollToTopController extends Controller {

  scroll({ params : { behavior } } : ScrollBehaviorParamsInterface ) {
    const scrollBehavior = behavior ?? "instant"
    window.scrollTo({
      top: 0,
      behavior: scrollBehavior,
    })
  }
}
