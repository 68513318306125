import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "logo", "text", "button"]
  readonly formTarget: HTMLFormElement
  readonly hasFormTarget: boolean
  readonly logoTarget: HTMLImageElement
  readonly hasLogoTarget: boolean
  readonly textTarget: HTMLSpanElement
  readonly hasTextTarget: boolean
  readonly buttonTarget: HTMLButtonElement
  readonly hasButtonTarget: boolean

  static classes = ["show", "hide", "disabled"]
  readonly showClass: string
  readonly hideClass: string
  readonly disabledClass: string

  onSubmit(event: Event): void {
    // We send an event to notify inputs to check itself
    if (this.hasFormTarget && this.formTarget.id) {
      const checkEvent = new CustomEvent("valid-form:" + this.formTarget.id)
      window.dispatchEvent(checkEvent)
    }

    // Handle waiting animation
    // if not valid we prevent the event to avoid getting the native tooltip
    this.formTarget.checkValidity()
      ? this.desactivateSubmitButton()
      : event.preventDefault()
  }

  validateVisibleInputs(event: Event): void {
    // We send an event to notify inputs to check themself
    if (this.hasFormTarget && this.formTarget.id) {
      const checkEvent = new CustomEvent("valid-form:" + this.formTarget.id)
      window.dispatchEvent(checkEvent)
    }

    // We look for all the inputs of the form and we check if they are visible and invalid, if this is the case we prevent the submission
    const haveInvalidInput = Array.from(this.formTarget.elements).find(
      (input : HTMLInputElement) => input.nodeName === "INPUT" && input.offsetHeight > 0 && !input.checkValidity()
    ) 

    haveInvalidInput ? event.preventDefault() : this.desactivateSubmitButton()
  }

  private desactivateSubmitButton(): void {
    if (this.hasTextTarget) {
      this.textTarget.classList.add(this.hideClass)
    }
    if (this.hasLogoTarget) {
      this.logoTarget.classList.replace(this.hideClass, this.showClass)
    }
    if (this.hasButtonTarget) {
      this.buttonTarget.classList.add(this.disabledClass)
    }
  }
}
