import { Controller } from "@hotwired/stimulus"

/*
This controller emits a signal depending on wether the element 
it is attached to intersects with the viewport (i.e. is visible).

When the element becomes visible, the event is intersect:enter
When the element stops being visible, the event is intersect:exit

The controller can also be assigned a name, which will be included in the 
events (intersect:<name>:enter, intersect:<name>:exit)
*/

export default class extends Controller {
  static values = {
    name: String,
    // Threshold 0 means that we consider the element to be intersecting
    // with the viewport as long as it is visible (even one pixel is enough)
    // For full intersection (element entirely visible), set to 1
    threshold: {
      type: Number,
      default: 0,
    },
  }

  private nameValue: string
  private hasNameValue: boolean

  private thresholdValue: number

  private observer: IntersectionObserver

  public connect(): void {
    this.observer = new IntersectionObserver(
      (entries) => {
        const lastEntry = entries[entries.length - 1]
        this.onUpdate(lastEntry.isIntersecting)
      },
      { threshold: this.thresholdValue },
    )

    this.observer.observe(this.element)
  }

  private onUpdate(intersecting: boolean): void {
    const prefix = this.hasNameValue ? this.nameValue + ":" : ""
    this.dispatch(prefix + (intersecting ? "enter" : "exit"))
  }
}
