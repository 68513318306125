import { Controller } from "@hotwired/stimulus"
import { getAccountData } from "@models/account-data"
import {
  getItemFromSessionStorage,
  setItemInSessionStorage,
} from "@utils/session_storage/session_storage.utils"

const ADAPTATION_SERVICE_KEY = "adaptationService"
const SUPPORTED = "false"

export default class extends Controller {
  static values = {
    url: String,
  }
  readonly urlValue: string

  static targets = ["select", "button", "message"]
  readonly selectTarget: HTMLSelectElement
  readonly buttonTarget: HTMLButtonElement
  readonly messageTarget: HTMLElement

  static classes = ["hideMessage"]
  readonly hideMessageClasses: string[]

  // Return TwoLetterISORegionName (ex: AF, DZ...) or false if allowed
  readonly adaptationService = getItemFromSessionStorage(ADAPTATION_SERVICE_KEY)

  async connect(): Promise<void> {
    const data = await getAccountData()
    if (this.adaptationService === SUPPORTED || !data.HasServiceAdaptation) {
      return
    }
    this.element.setAttribute("src", this.urlValue)
  }

  selectTargetConnected(): void {
    if (this.adaptationService) {
      this.selectTarget.value = this.adaptationService
      this.lockIfNotSupported()
    }
  }

  lockIfNotSupported(): void {
    const isSupported =
      this.selectTarget.selectedOptions[0].dataset.supported === "True"

    if (isSupported) {
      setItemInSessionStorage(ADAPTATION_SERVICE_KEY, SUPPORTED)
      this.element.remove()
    } else {
      setItemInSessionStorage(ADAPTATION_SERVICE_KEY, this.selectTarget.value)
      this.buttonTarget.remove()
      this.selectTarget.disabled = true
      this.messageTarget.classList.remove(...this.hideMessageClasses)
    }
  }
}
