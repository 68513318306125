import { Controller } from "@hotwired/stimulus"
import {
  getCookie,
  hasCookie,
  setCookieWithDurationInDays,
} from "@utils/cookie_storage/cookie_storage.utils"
import { getAccountData } from "@models/account-data"
export default class extends Controller {
  static targets = [
    "informAboutInterimConfirmation",
    "isAuthenticated",
    "isNotAuthenticated",
  ]
  readonly informAboutInterimConfirmationTarget: HTMLElement
  readonly isAuthenticatedTarget: HTMLElement
  readonly isNotAuthenticatedTarget: HTMLElement

  static values = {
    isEligibleForDisplay: Boolean,
  }
  readonly isEligibleForDisplayValue: boolean

  static classes = ["hide"]
  readonly hideClass: string
  private informAboutInterimCookie: string | undefined

  connect() {
    this.createInformAboutInterimCookie()
    this.displayIfNeeded()
  }

  createInformAboutInterimCookie() {
    if (!hasCookie("informAboutInterim")) {
      setCookieWithDurationInDays(
        "informAboutInterim",
        '{"informAboutInterim": "undefined", "boxHasBeenDisplayed": "false"}',
        60,
      )
    }
    this.informAboutInterimCookie = getCookie("informAboutInterim")
  }

  displayIfNeeded(): void {
    const cookie = JSON.parse(this.informAboutInterimCookie)
    const temporaryWorkFacet: HTMLLabelElement = document.querySelector(
      'select[name="c"] option#Travail_temp',
    )
    if (temporaryWorkFacet.getAttribute("selected") != "selected") {
      if (
        cookie.informAboutInterim === "undefined" &&
        this.isEligibleForDisplayValue
      ) {
        setCookieWithDurationInDays(
          "informAboutInterim",
          '{"informAboutInterim": "undefined", "boxHasBeenDisplayed": "true"}',
          60,
        )
        this.element.classList.remove(this.hideClass)
        window.dataLayer.push({
          event: "EventGeneric",
          EventCat: "Modale",
          EventAction: "Load",
          EventLibelle: "[LO-Moteur] - Box interim",
        })
      }
    }
  }

  setCookieOnSubmit() {
    setCookieWithDurationInDays(
      "informAboutInterim",
      '{"informAboutInterim": "true", "boxHasBeenDisplayed": "true"}',
      60,
    )
  }

  async confirmationMessage(): Promise<void> {
    const accountData = await getAccountData()
    this.informAboutInterimConfirmationTarget.classList.remove(this.hideClass)
    if (accountData.IsAuthenticated) {
      this.isAuthenticatedTarget.classList.remove(this.hideClass)
      this.isNotAuthenticatedTarget.classList.add(this.hideClass)
    } else {
      this.isAuthenticatedTarget.classList.add(this.hideClass)
      this.isNotAuthenticatedTarget.classList.remove(this.hideClass)
    }
  }
  close(): void {
    this.element.classList.add(this.hideClass)
  }
}
