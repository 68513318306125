import { Controller } from "@hotwired/stimulus"
import { setItemInSessionStorage } from "@utils/session_storage/session_storage.utils"
import { setCookieWithDurationInDays } from "@utils/cookie_storage/cookie_storage.utils"
import {
  AT_LEAST_SECOND_PAGE_KEY,
  HAS_CLOSED_BANNER_KEY,
  isAtLeastSecondPage,
  isSmartBannerDisplayable,
} from "@utils/smart_banner/smart_banner.utils"

export default class extends Controller {
  static values = {
    url: String,
  }
  readonly urlValue: string

  connect(): void {
    if (!isSmartBannerDisplayable()) {
      return
    }

    if (isAtLeastSecondPage()) {
      this.element.setAttribute("src", this.urlValue)
    }

    setItemInSessionStorage(AT_LEAST_SECOND_PAGE_KEY, "1")
  }

  close(): void {
    setCookieWithDurationInDays(HAS_CLOSED_BANNER_KEY, "1", 30)
    this.element.remove()
  }
}
