import { Controller } from "@hotwired/stimulus"
import { SubmitEvent } from "@models/submit-event"
import FetchService from "@services/fetch_service"

export default class extends Controller {
  submit(event: SubmitEvent) {
    event.preventDefault()

    const form = <HTMLFormElement>event.target
    const formData = new FormData(form)
    const payload: any = {}
    const customHeaders = new Headers()
    customHeaders.append("Content-Type", "application/json")

    for (const [key, value] of formData) {
      payload[key] = value
    }
    const service = new FetchService(form.action)
    service.setHeaders(customHeaders)
    service.post(JSON.stringify(payload), this.handleResponse.bind(this))
    service.setErrorHandler((error: any) => {
      this.dispatch("failure", { detail: error })
    })
  }
  handleResponse(data: any): void {
    this.dispatch("success", { detail: data })
  }
}
