import MESSAGES from "../default_messages.constants"

/**
 *
 * @param state
 * @param input
 */
export function getMessageFromErrorAndInput(state: ValidityState, input: string): string {
  switch (true) {
    case state.valueMissing:
      return onValueMissing(input)

    case state.patternMismatch:
      return onPatternMismatch(input)

    case state.tooShort:
      return onTooShort(input)

    default:
      // By default, we return a random response
      return MESSAGES.DEFAULT
  }
}

/**
 *
 * @param input
 */
function onValueMissing(input: string): string {
  switch (input) {
    case "Firstname":
      return MESSAGES.VALUE_MISSING.FIRSTNAME

    case "Name":
      return MESSAGES.VALUE_MISSING.NAME

    case "Email":
      return MESSAGES.VALUE_MISSING.EMAIL

    case "Resume":
      return MESSAGES.VALUE_MISSING.RESUME

    case "job_autocomplete":
    case "Job.Uri":
      return MESSAGES.VALUE_MISSING.JOB

    case "job_autocomplete-multiple":
      return MESSAGES.VALUE_MISSING.JOB_MULTIPLE

    case "locality_autocomplete":
    case "Location.Uri":
    case "Location.DisplayLabel":
      return MESSAGES.VALUE_MISSING.LOCALITY

    case "location-autocomplete-multiple":
      return MESSAGES.VALUE_MISSING.LOCALITY_MULTIPLE

    case "Contracts":
      return MESSAGES.VALUE_MISSING.CONTRACTS

    case "job_domain_autocomplete":
      return MESSAGES.VALUE_MISSING.JOB_AND_DOMAIN

    case "OneTimePassword":
      return MESSAGES.VALUE_MISSING.OTP

    default:
      return `${MESSAGES.VALUE_MISSING.RANDOMS[Math.floor(Math.random() * MESSAGES.VALUE_MISSING.RANDOMS.length)]}`
  }
}

/**
 *
 * @param name
 */
function onPatternMismatch(name: string): string {
  // Only email has different message when regex fail
  switch (name) {
    case "Email":
      return MESSAGES.PATTERN_MISSMATCH.EMAIL

    case "PhoneNumber":
      return MESSAGES.PATTERN_MISSMATCH.PHONE

    case "CandidateZipCode":
      return MESSAGES.PATTERN_MISSMATCH.ZIP_CODE

    case "OneTimePassword":
      return MESSAGES.PATTERN_MISSMATCH.OTP

    default:
      return MESSAGES.PATTERN_MISSMATCH.DEFAULT
  }
}

function onTooShort(name: string): string {
  switch (name) {
    case "OneTimePassword":
      return MESSAGES.TOO_SHORT.OTP

    default:
      return MESSAGES.TOO_SHORT.DEFAULT
  }
}
