import { Controller } from "@hotwired/stimulus"
import { setCookieWithDurationInMinutes } from "@utils/cookie_storage/cookie_storage.utils"

export default class extends Controller {
  static values = {
    cookiesList: String,
  }
  readonly cookiesListValue: string

  track(): void {
    const queryString = window.location.search
    if (!queryString) return

    const urlParams = new URLSearchParams(queryString)
    const cookiesList = this.cookiesListValue.split(",")

    for (const cookieName of urlParams.keys()) {
      if (cookiesList.includes(cookieName)) {
        const token = urlParams.get(cookieName)
        setCookieWithDurationInMinutes(cookieName, token, 60)
      }
    }
  }
}
