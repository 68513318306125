import { Controller } from "@hotwired/stimulus"
import {
  getCookie,
  setCookieWithDurationInDays,
} from "@utils/cookie_storage/cookie_storage.utils"

export const OPENED_BY_ATTR = "data-opened-by"

export default class extends Controller {
  static values = {
    checkId: String,
    modalId: String,
    propName: { type: String, default: "__hw_seo_modal_autotriggered" },
  }

  private checkIdValue: string
  private modalIdValue: string
  private propNameValue: string

  private can_trigger: boolean
  private checkbox: HTMLInputElement
  private alertModal: HTMLElement

  public connect(): void {
    this.can_trigger = this.checkSession()
    this.checkbox = <HTMLInputElement>document.getElementById(this.checkIdValue)
    this.alertModal = document.getElementById(this.modalIdValue)
  }

  private checkSession(): boolean {
    return getCookie(this.propNameValue) !== "true"
  }

  // Method to open the three criteria alert modal
  public open(): void {
    // Only trigger if never triggered before and if the modal is not currently opened
    if (this.can_trigger && !this.checkbox.checked) {
      // Prevent further triggers
      this.setTriggered()
      // Sets a data-attribute on the modal to tell it to send a special tracking event
      this.alertModal.setAttribute(OPENED_BY_ATTR, "seo-open-modal")
      // Opens the modal (is only displayed when the checkbox is checked)
      this.checkbox.checked = true
      // If the user closes the modal, remove the data-attribute indicating that it was opened by this controller
      this.checkbox.addEventListener(
        "change",
        () => {
          console.log(this.checkbox.checked)
          this.alertModal.removeAttribute(OPENED_BY_ATTR)
        },
        { once: true },
      )
    }
  }

  // Upon first activation, disables further activation for this day
  private setTriggered(): void {
    this.can_trigger = false
    setCookieWithDurationInDays(this.propNameValue, "true", 0)
  }
}
